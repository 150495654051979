<template>
  <div id="app">
    <template v-if="canShow">
      <router-view />
    </template>
  </div>
</template>
<script>
import { getPermission, checkLogin, getToken } from "@/utils/auth";
import { mapMutations } from "vuex";
import {getSecureLogin, getWechatSet} from "./api/setting";
import { getCmsApiDomain } from "@/api/push";
import actionMixin from "@/mixin/action";
import {getBasicInfo,basicInfo,storageConfig} from "./api/common"

export default {
  mixins: [actionMixin],
  name: "App",
  data() {
    return {
      canShow: false,
    };
  },
  created() {
    this._baseInfo()
    this._storageConfig()
    let userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      this.SET_USERINFO({ userInfo: JSON.parse(userInfo) });
    }
    this.getWechatSet();
    // 获取cms接口调用域名
    this._getCmsApiDomain();
    this._getSecureLogin()
    this.getBasicInfo()
    // if (this.$route.path.indexOf("login") != -1) {
    //     checkLogin(); //调用登录接口
    //     let data = getToken("userInfo");
    //     if (data) {
    //         this.$store.commit("SET_USERINFO", JSON.parse(data));
    //     }
    //     getPermission(); //获取权限
    // }
  },
  methods: {
    ...mapMutations(["SET_USERINFO","setLogConfigInfo"]),
    ...mapMutations("base", ["setDomias"]),
    _baseInfo() {
      basicInfo().then(res => {
          if (res.code == 100000) {
            localStorage.setItem("domain", JSON.stringify(res.data));
          }
        }).catch(err => {
          console.log(err)
        })
    },
    // 获取当前环境是vms还是mam
    _storageConfig() {
        storageConfig().then(res => {
          if(res.code == 100000) {
            localStorage.setItem("storageConfig", JSON.stringify(res.data.storageConfig));
          }
        })
    },
    getWechatSet() {
      getWechatSet()
        .then((res) => {
          console.log("app get wechat set");
          console.log(res);
          if (res.code == 100000) {
            sessionStorage.setItem("wechatInfo", res.data.wechat_login);
            // this.wechat_login = parseFloat(res.data.wechat_login);
          } else {
            this.$message.error(res.msg);
          }
          this.canShow = true;
        })
        .catch((err) => {
          this.canShow = true;
          console.log(err);
        });
    },
    _getCmsApiDomain() {
      getCmsApiDomain().then((res) => {
        if (res.code == 100000) {
          console.log("cms域名=======", res.data);
          sessionStorage.setItem("cmsApi", res.data.cms_api.api_domain);
          localStorage.setItem('cmsApi',res.data.cms_api.api_domain)
          sessionStorage.setItem(
            "cmsPlatUrl",
            res.data.cms_api.app_platform_url
          );
        }
      });
    },
    async handleActionChange(state, prev) {
      // console.log(`state`, state)
      console.log(`改变`, state.currentPage.app);
      if (
        state.currentPage.app === "ucenter_p" &&
        prev.currentPage.route !== state.currentPage.route
      ) {
        this.$router.replace({
          path: state.currentPage.route,
        });
      }
    },
    /**
     @description:获取安全登录配置 2022-10-12
     **/
    _getSecureLogin(){
      let params = {
        type:1,
      }
      getSecureLogin(params).then(res=>{
        if(res.code == 100000){
          this.setLogConfigInfo(res.data)
        }
      })
    },
    /**
     * @description:获取后端AIP域名
     */
    getBasicInfo() {
      // let url = 'http://api-cms.staging.slradio.cn'
      let params = {
        app:'vms_api_domain;cms_api_domain;mp_api_domain;mp_p_domain;ucenter_app_id'
      }
      getBasicInfo(params).then((res) => {
          // 保存到vuex
          if (res.code == "100000") {
            console.log('获取用户基本信息',res)
            this.setDomias({ data: res.data });
          }
        })
        .catch((err) => {

        });
    },
  },
  watch: {
    $route: () => {
      if (document.getElementById("main")) {
        document.getElementById("main").scrollTop = 0;
      }
    },
  },
};
</script>
