import {setToken,getToken} from '../../utils/auth'
const state = {
  userInfo: {
    admin :"",
    create_by:"",
    created_at : null,
    department_id :"1",
    email:"",
    head_image:null,
    id:1,
    phone:"",
    position:"",
    real_name: "",
    remark:null,
    remember_token: "",
    sso_token:null,
    status:"1",
    username:"hg",
    permissions:[],
    is_admin:0,
  },
  logConfigInfo: null,
}
const getters = {
    userInfo: state => {
        let userInfo=state.userInfo;
        if(localStorage.getItem('userInfo')){
            state.userInfo=JSON.parse(localStorage.getItem('userInfo'));
            state.userInfo=userInfo;
        }
        return userInfo;
    },
  logConfigInfo: state => {
    let logConfigInfo=state.logConfigInfo;
    return logConfigInfo;
  }

}
const mutations = {
  //更新用户信息
  SET_USERINFO(state, params) {
      console.log(params.userInfo,1111)
    state.userInfo = params.userInfo;
    // setToken('userInfo',JSON.stringify(newUserInfo));
    // localStorage.userInfo = newUserInfo; //把信息存入本地
    localStorage.setItem('userInfo',JSON.stringify(params.userInfo));
  },
  //获取安全登录配置
  setLogConfigInfo(state, data){
    console.log('datadata少时诵诗书是是是',data)
    state.logConfigInfo = data
    console.log('state.logConfigInfo',state.logConfigInfo)
  }

}
export default {
  state,
  getters,
  mutations
}
