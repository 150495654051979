import Vue from 'vue'
import {findArray} from './utils'
/*****
 * @description 权限指令
 * @Author:lynn
 * time:2018-07-17
 * 
 * ****** */
const hasDirective = Vue.directive('has', {
    inserted: function (el, binding) {
        console.log('el',binding,el.parentNode)
        if (!Vue.prototype.$_has(binding.value)) {
            el.parentNode.removeChild(el);
        }
    }
});
//权限检查方法
Vue.prototype.$_has = function (value) {
    // debugger
    let isExist = false;
    let buttonpermsStr = JSON.parse(sessionStorage.getItem('buttenpremissions'));
    if (buttonpermsStr == undefined || buttonpermsStr == null) {
        return false;
    }
    if (buttonpermsStr.admin === '1') {//如果是超级用户 那么拥有所有权限
        isExist = true
    }else{
        let findIndex;
        if(value){
             findIndex = findArray(buttonpermsStr.data, { real_name: value.toString() });
        }
        if (parseInt(findIndex) != -1) {
            isExist = true
        }
    }
    return isExist;
}
export default hasDirective