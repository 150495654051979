// 基础
const state = {
  domias: {
    asc_c_domain: '',
    asc_h5_domain: '',
    cms_c_domain: '',
    ucenter_api_domain: ''
  }
};

const getters = {
  getDomias: state => {
    return state.domias
  }
}

const mutations = {
  setDomias(state, {
    data
  }) {
    state.domias = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
}