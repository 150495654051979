// 基础
const state = {
  merchants: [],
  merchantsAll: [],
};

const getters = {
  getMerchants: state => {
    return state.merchants
  },
  getMerchantsAll: state => {
    return state.merchantsAll
  },
}

const mutations = {
  setMerchants(state, {
    data
  }) {
    state.merchants = data;
  },
  setMerchantsAll(state, {
    data
  }) {
    state.merchantsAll = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
}