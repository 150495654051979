import axios from 'axios'
import {
  Message
} from 'element-ui'
import { cookie } from '@/utils/cookie';
import actions from '@/actions'//导入实例

let NODE_ENV = process.env.NODE_ENV;
let ENV_CONFIG = process.env.ENV_CONFIG;
// let API_ROOT = '';
// switch (NODE_ENV) {
//     case 'review':
//         API_ROOT = 'http://api-i.review.slradio.cn'
//         break;
//     case 'staging':
//         API_ROOT = 'http://api-i.staging.slradio.cn'
//         break;
//     case 'production':
//         API_ROOT = 'http://api-i.slradio.cn'
//         break;
//     default:
//         API_ROOT = 'http://api-i.review.slradio.cn'
// }
let API_BASE = process.env.VUE_APP_API_BASE || '/api';
// 乾坤开发环境
if (process.env.NODE_ENV === 'development' && window.__POWERED_BY_QIANKUN__) {
  API_BASE = "/api-i"
}
// axios.defaults.withCredentials = true;
const service = axios.create({
  baseURL: API_BASE,
  // baseURL:'http://api-i.review.slradio.cn',
  timeout: 15000, // 请求超时时间
  //   withCredentials: true
})
// request拦截器
service.interceptors.request.use(config => {
  config.headers['Authorization'] = cookie.get('Authorization');
  //   config.headers['X-CSRF-TOKEN'] = document.querySelector('meta[name=csrf-token]').getAttribute('content') // 让每个请求携带自定义token 请根据实际情况自行修改
  return config
}, error => {
  // Do something with request error
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非0是抛错
     */
    const res = response.data
    let str = window.location.hostname.substring(window.location.hostname.indexOf('.'))
    const ENV_TOP_DOMAIN = process.env.VUE_APP_TOP_DOMAIN;
    cookie.set('Authorization', cookie.get('Authorization'), 2, ENV_TOP_DOMAIN || str);
    if (!res) {
      return response;
    }
    if (window.__POWERED_BY_QIANKUN__) {
      actions.setGlobalState({ responseCode: 100000 })
    }
    if (res.code !== 100000 && res.code != '103002' && res.code != '202036' && res.code != '202034') {
      if (response.config.url.indexOf('phone/register') != -1) {
        return response.data;
      } else {
        switch (res.code) {
          case 303001: //登录失效获取这未登录跳进登录页面
            if (window.__POWERED_BY_QIANKUN__) {
              actions.setGlobalState({ responseCode: 303001 })
              return response.data
            }
            Message({
              message: res.msg,
              type: 'error',
              duration: 3000
            });
            setTimeout(() => {
              window.location.href = 'http://' + window.location.host + '/login';
            }, 3000);
            // break;
          case 302001://用户权限不足
            if (window.__POWERED_BY_QIANKUN__) {
              actions.setGlobalState({ responseCode: 302001 })
              return response.data
            }
            Message({
              message: res.msg,
              type: 'error',
              duration: 3000
            });
            // setTimeout(()=>{
            //     window.location.href = 'http://' + window.location.host + '/login';
            // },3000);
            break;
          case 200004: //权限已经更改
            Message({
              message: res.msg,
              type: 'error',
              duration: 3000
            });
            setTimeout(() => {
              location.reload();
            }, 3000)
            break;
          default:
            // Message({
            //   message: res.msg,
            //   type: 'error',
            //   duration: 3000
            // })
            return response.data;
            break;
        }

        return Promise.reject('error')
      }
    } else {
      return response.data
    }
  },
  error => {
    if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1) {
      console.log('超时')
      return Promise.reject(error);
    }
    if (!error.response) {
      Message({
        message: '检查你的网络状态',
        type: 'error',
        duration: 5 * 1000
      });
      return
    }
    if (error.response.status === 419) {
      window.location.reload();
    }
    if (error.response.status === 500) {
      let msg = `请求失败 ${error.response.status}`
      Message({
        message: msg,
        type: 'error',
        duration: 5 * 1000
      });
    }
    if (error.response.status === 422) {
      Message({
        message: error.response.message.errors.file[0],
        type: 'error',
        duration: 5 * 1000
      });
    }
    return Promise.reject(error)
  }
)
export default service
