import request from '@/utils/request'

// 获取行政区划位置信息
export function getPLocation(params) {
  return request({
    url: '/v1/platform/location',
    method: 'get',
    params
  })
}

//更新角色接口
export function setPLocation(params) {
  return request({
    url: '/v1/platform/location',
    method: 'put',
    data: params
  })
}

// 一键生成
export function initPLocation(params) {
  return request({
    url: '/v1/platform/administrations/init',
    method: 'post',
    data: params
  })
}

// 微信登录
export function getWechatSet(params) {
  return request({
    url: '/v1/system/app_configs',
    method: 'get',
    params
  })
}

// 设置微信登录
export function setWechatSet(params) {
  return request({
    url: '/v1/system/app_configs',
    method: 'put',
    params
  })
}

/*
  设置安全登录配置 2022-10-12
 */
export function setSecureLogin(params) {
  return request({
    url: '/v1/secure_login/configs',
    method: 'put',
    params
  })
}
/*
  获取安全登录配置 2022-10-12
 */
export function getSecureLogin(params) {
  return request({
    url: '/v1/secure_login/configs',
    method: 'get',
    params
  })
}
