import request from '@/utils/request';

/*
 * 获取cms接口域名  --蒋红
 * */
export function getCmsApiDomain() {
    return request({
        url: '/v1/cms/api_domain',
        method: 'get'
    })
}

/*
 * 专辑管理、文章管理列表  --危杰
 * */
export function getListInfo(params) {
    return request({
        // 访问cms接口，需要替换baseUrl
        baseURL: sessionStorage.getItem('cmsApi'),
        url: '/v1/posts',
        method: 'get',
        params
    })
}
/*
 * 专辑管理列表  --韦怡
 * */
export function getAlbumList(params) {
    return request({
        baseURL: sessionStorage.getItem('cmsApi'),
        url: '/v1/platform_albums',
        method: 'get',
        params
    })
}


/*
 * 推送配置保存  --蒋红
 * */
export function saveMsgPushConfig(params) {
    return request({
        baseURL: sessionStorage.getItem('cmsApi'),
        url: '/v3/save_message_push_config',
        method: 'post',
        data: params
    })
}

/*
 * 推送配置查询  --蒋红
 * */
export function getMsgPushConfig(params) {
    return request({
        baseURL: sessionStorage.getItem('cmsApi'),
        url: `/v3/get_message_push_config`,
        method: 'get',
        params
    })
}

/*
 * 查询当天未发送数量  --蒋红
 * */
export function getUnsentCount(params) {
    return request({
        baseURL: sessionStorage.getItem('cmsApi'),
        url: `/v3/get_unsent_count`,
        method: 'get',
        params
    })
}

/*
 * 获取C端用户  --蒋红
 * */
export function getUser(params) {
    return request({
        baseURL: sessionStorage.getItem('cmsApi'),
        url: `/v3/get_user`,
        method: 'get',
        params
    })
}

/*
 * 推送消息  --蒋红
 * */
export function messagePush(params) {
    return request({
        baseURL: sessionStorage.getItem('cmsApi'),
        url: `/v3/messages_push`,
        method: 'post',
        data: params
    })
}

/*
 * 获取推送历史记录  --蒋红
 * */
export function getMsgHistory(params) {
    return request({
        baseURL: sessionStorage.getItem('cmsApi'),
        url: `/v3/get_messages`,
        method: 'get',
        params
    })
}

/*
 * 获取推送历史详情  --蒋红
 * */
export function getMsgDetails(params) {
    return request({
        baseURL: sessionStorage.getItem('cmsApi'),
        url: `/v3/get_messages_details`,
        method: 'get',
        params
    })
}

/*
 * 获取平台增值服务详情  --危杰
 * */
export function getUsingResource(params) {
    return request({
        url: `/v1/platform/service_module_using`,
        method: 'get',
        params
    })
}
