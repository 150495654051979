/*
 * cookie设置
 * */
export const cookie = {
  set: function (key, value, hours, domain) {
    if (!value && value !== 0) value = "";
    let cookieStr = '';
    cookieStr = `${key}=${value};`;
    if (hours) {
      let exp = new Date();
      exp.setTime(exp.getTime() + hours * 60 * 60 * 1000);
      cookieStr += `expires=${exp.toGMTString()};`;
    }
    if (!domain) {
      domain = window.location.hostname.slice(window.location.hostname.indexOf('.'))
    }
    cookieStr += `domain=${domain};`;
    document.cookie = cookieStr + 'path=/';
  },
  get: function (name) {
    let reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)', 'g');
    if (document.cookie.match(reg) && document.cookie.match(reg).length) {
      // 匹配cookie（若有多个，匹配最后即最新的一个）
      const {
        length
      } = document.cookie.match(reg);
      const matchStr = document.cookie.match(reg)[length - 1]
      if (matchStr) {
        let res = matchStr.match(/=([^;]*)(;|$)/);
        return res[1];
      } else {
        return null;
      }
    } else {
      return null;
    }
  },
  del: function (name) {
    const value = this.get(name);
    if (!value) {
      return;
    }
    let domain = window.location.hostname.substring(window.location.hostname.indexOf('.'));
    var regIp=/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;            
    if(regIp.test(window.location.hostname)){
      domain=window.location.hostname;
    }
    const ENV_TOP_DOMAIN = process.env.VUE_APP_TOP_DOMAIN;
    this.set(name, '', -1, ENV_TOP_DOMAIN || domain);
  },
  clear: function () {
    let date = new Date();
    date.setTime(date.getTime() - 10000);
    //keys为所要删除cookie的名字
    let keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    //删除所有cookie
    if (keys) {
      for (let i = keys.length; i--;)
        document.cookie = keys[i] + "=0; expire=" + date.toGMTString() + "; path=/";
    }
  },
};
