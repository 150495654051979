<template>
  <div class="app-wrapper" :class="{'qiankun': __POWERED_BY_QIANKUN__}">
    <navbar :userInfo="userInfo" :collapse.sync="collapse" @handleLicenseDiag="handleLicenseDiag" v-if="!__POWERED_BY_QIANKUN__"></navbar>
    <div class="layout-body" :class="{noTop:__POWERED_BY_QIANKUN__ }">
      <sidebar
        :collapse.sync="collapse"
        class="sidebar-container"
        :isAdmin="userInfo?userInfo.is_admin:0"
        v-if="$route.fullPath !== '/password' && !__POWERED_BY_QIANKUN__"
      ></sidebar>
        <!-- :style="__POWERED_BY_QIANKUN__? 'top:64px;': ''" -->
      <div
        class="main-container"
        :class="{'no-left-container':$route.fullPath == '/password' || __POWERED_BY_QIANKUN__,collapse}"
      >
        <app-main></app-main>
        <div class="license-diag">
          <el-dialog :visible.sync="showLicenseDiag" width="916px">
            <div class="license-title">
              <span>菱云系统授权信息</span>
            </div>
            <div class="license-content">
              <div class="card-img">
                <div class="card-left">
                  <div class="license-info" style="font-size: 22px; margin-bottom: 10px;">{{publicResour.platform_name}}</div>
                  <div class="license-info">
                    菱云系统使用有效期：
                    <span
                      style="color: #FFA235;"
                    >{{publicResour.license_at ? moment(publicResour.license_at).format('YYYY-MM-DD') : '永久'}}</span>
                  </div>
                  <div class="license-info">
                    技术服务到期时间: {{ publicResour.expired_at ? '剩余'+ timeDifference(moment(publicResour.expired_at).format('YYYY-MM-DD')) + '天' : '永久'}}
                    <span
                      style="color: #FFE157;"
                      v-if="publicResour.expired_at"
                    >{{'（'+moment(publicResour.expired_at).format('YYYY-MM-DD') + '止）' }}</span>
                  </div>
                </div>
                <div class="card-right">
                  <img src="../../assets/image/user-center/licens-card-img.png" alt />
                </div>
              </div>
              <div class="tooltip-text">
                <div>
                  <span>·本软件由湖南双菱电子科技有限公司独立开发</span>
                </div>
                <div>
                  <span>·本软件的版权属于湖南双菱电子科技有限公司，未经湖南双菱电子科技有限公司授权许可不得擅自发布或使用该软件</span>
                </div>
                <div>
                  <span>·警告：本计算机程序受著作权法和国际公约的保护，未经授权擅自复制或散发本程序的部分或全部，将承受严厉的民事和刑事处</span>
                </div>
                <div style="margin-left: 49px;">
                  <span>罚,对已知的违法者将给予法律范围内的全面制裁。</span>
                </div>
              </div>
              <div class="company-area">
                <div class="company-left">
                  <div class="company-info">
                    <div class="company-line"></div>
                    <div class="company-content">
                      <span class="title">湖南双菱电子科技有限公司官网</span>
                      <span class="content" style="color:#158BFF; cursor: pointer;">
                        <a href="http://www.shuangln.com" target="_Blank">www.shuangln.com</a>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="company-right">
                  <div class="company-info">
                    <div class="company-line"></div>
                    <div class="company-content">
                      <span class="title">湖南双菱电子科技有限公司服务热线</span>
                      <span class="content">0731-85170040/41/42/43/44/45/46/47</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="split-line"></div>
            <div class="copyright">
              <span>版权所有者©湖南双菱电子科技有限公司</span>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar/index";
import AppMain from "./components/AppMain";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapGetters, mapState } from "vuex";
import { getBindInfo } from "../../api/user";
import { getPublicResour } from "@/api/billCenter";
import moment from "moment";
export default {
  name: "layout",
  mixins: [ResizeMixin],
  data() {
    return {
      collapse: false,
      showLicenseDiag: false,
      publicResour: {},
      moment,
    };
  },
  components: {
    Navbar,
    Sidebar,
    AppMain,
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },

    ...mapGetters(["userInfo"]),
    // ...mapState(['userInfo'])
  },
  watch: {
    userInfo: {
      handler(newVal) {
        console.log("handler", newVal);
      },
      deep: true,
    },
  },
  created() {
    // this.getBindInfo();
  },
  mounted() {
    console.log("layout,mounted", this.userInfo, this.$route);
  },
  methods: {
    timeDifference(license_at) {
      license_at = new Date(license_at.replace(/-/g, "/"));
      let current_at = new Date(); //当前日期：2017-04-24
      let time = license_at.getTime() - current_at.getTime();
      let days = parseInt(time / (1000 * 60 * 60 * 24));
      return days;
    },
    handleLicenseDiag() {
      this._getPublicResour();
      this.showLicenseDiag = true;
    },
    async _getPublicResour() {
      const res = await getPublicResour();
      console.log("通用资源", res);
      if (res.code !== 100000) {
        return this.$message.error(res.msg);
      }
      this.publicResour = res.data;
    },
    getBindInfo() {
      if (
        !localStorage.getItem("needGetBind") ||
        sessionStorage.getItem("wechatInfo") != 1
      ) {
        return;
      }
      getBindInfo()
        .then((res) => {
          localStorage.removeItem("needGetBind");
          console.log(res);
          const isBindWechat = res.data.find((item) => {
            return item.app_type == 0;
          });
          if (!isBindWechat) {
            localStorage.setItem("notBindWechat", 1);
            // localStorage.setItem('needBindWechat', 1);
            this.$confirm("绑定微信，登录更快捷更安全", "提示", {
              confirmButtonText: "立刻绑定",
              cancelButtonText: "以后再说",
              type: "info",
            })
              .then(() => {
                localStorage.setItem("needPop", 1);
                this.$router.push("/userCenter");
              })
              .catch(() => {});
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../assets/sass/helpers/_mixin.scss";
@import "../../assets/sass//helpers/_variables";

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 1300px;

  &.qiankun {
    min-width: initial;
  }
  .layout-body {
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;

    &.noTop {
      top: 0;
    }
    // 主体区域
    .main-container {
      position: relative;
      height: 100%;
      transition: margin-left 0.28s;
      margin-left: $sider-w;
      background: $content-Bg;
      overflow: auto;
      &.collapse {
        margin-left: 64px;
      }
      &.no-left-container {
        margin-left: 0;
      }
    }
    .license-diag {
      .license-title {
        height: 23px;
        font-size: 23px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #001326;
        line-height: 25px;
        height: 23px;
        text-align: center;
      }
      .license-content {
        margin: 35px 15px 20px;
        .card-img {
          height: 165px;
          background: linear-gradient(-90deg, #0063f7, #2aa2ff);
          border-radius: 3px;
          display: flex;
          justify-content: space-between;
          .card-left {
            padding: 40px 20px;
            .license-info {
              color: #ffffff;
              font-size: 17px;
            }
          }
        }
        .tooltip-text {
          margin: 10px 0 20px;
          font-size: 15px;
          color: rgba(0, 19, 38, 0.8);
        }
        .company-area {
          height: 90px;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.06);
          border-radius: 3px;
          display: flex;

          .company-info {
            display: flex;
            margin: 25px 0;
            .company-line {
              width: 3px;
              height: 39px;
              background: linear-gradient(0deg, #0063f7, #2ba7ff);
            }
            .company-content {
              display: flex;
              flex-direction: column;
              height: 40px;
              line-height: 15px;
              font-size: 13px;
              margin-left: 25px;
              .title {
                margin-bottom: 10px;
              }
              .content {
                color: #001326;
              }
            }
          }
          .company-left {
            width: 50%;
            border-right: 1px solid rgba(0, 0, 0, 0.06);
          }
          .company-right {
            width: 50%;
          }
        }
      }
      .split-line {
        height: 1px;
        background: #f5f5f5;
      }
      .copyright {
        margin: 20px 15px;
        font-size: 13px;
        color: rgba(0, 19, 38, 0.5);
      }
    }
  }
}
</style>
<style lang="scss">
.license-diag {
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
