const auth = () => import('@/views/syssetting/auth/auth');
const customer = () => import('@/views/syssetting/customer-user/customer-user');
const customerEdit = () => import('@/views/syssetting/customer-user/customer-edit');
const nopermission = () => import('@/views/no-permission');
const routerW=[
  {
    path:'/nopermission',
    name:'nopermission',
    component: nopermission,
    meta:{
      title:'权限不足',
      // menuId: '27',
    }
  },
    {
        path:'/sysSetting/auth',
        name:'auth',
        component: auth,
        meta:{
            title:'权限管理',
            menuId: '27',
        }
    },
    {
        path:'/sysSetting/customer',
        name:'customer',
        component: customer,
        meta:{
            title:'用户中心管理',
            menuId: '28',
        },
    },
    {
      path:'/sysSetting/customerEdit',
      name:'customerEdit',
      component: customerEdit,
      meta:{
          title:'用户中心管理',
          menuId: '28',
      }
  },
]

export default routerW;