import Vue from "vue";
import Router from "vue-router";
import layout from "@/views/layout/layout";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import store from "@/store/index";
import { cookie } from "@/utils/cookie";
import { Notification, MessageBox } from "element-ui";
import routerW from "./module/module-w";
import {getWxUserInfo,wxLogin} from '../api/login'
import { getPermission } from "@/utils/auth";
import { findArray } from "@/utils/utils";
// import {
//   getUserInfo
// } from '@/api/login';
import { getPersonalInfo } from "@/api/user";
import { mapGetters } from "vuex";
import { get } from "core-js/core/dict";

// 去除路由无用报错信息
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }

NProgress.configure({
  showSpinner: false,
}); // NProgress Configuration
Vue.use(Router);
const matrix = () => import('@/views/matrix/matrix'),
  login = () => import('@/views/login'),
  loginRecord = () => import('@/views/syssetting/login-record/login-record'),
  accountList = () => import('@/views/account/account'),
  accountType = () => import('@/views/account/accountType'),
  accountChoose = () => import('@/views/account/accountChoose'),
  //新闻机构
  accountOrgEdit = () => import('@/views/account/accountOrgEdit'),
  //行政机构和职能部门
  accountOrganizational = () => import('@/views/account/account-Organizational'),
  practiceOrganizational = () => import('@/views/account/practiceOrganizational'),
  accountPerEdit = () => import('@/views/account/accountPerEdit'),
  orgAccount = () => import('@/views/account-bill/account-bill'),
  accountBill = () => import('@/views/account-bill/new-account-bill'),
  accountOrder = () => import('@/views/account-order/account-order'),
  // xm---订单管理新建
  accountOrderEdit = () => import('@/views/account-order/account-orderEdit'),
  // xm---订单详情
  accountOrderDetail = () => import('@/views/account-order/account-orderDetail'),
  // xm---计费中心的续费
  accountRenew = () => import('@/views/account-bill/account-renew'),
  // xm ---计费详情
  accountBillDetail = () => import('@/views/account-bill/account-billDetail'),
  newAccountBillDetail = () => import('@/views/account-bill/new-account-bill-detail'),
  message = () => import('@/views/message/message'),
  // 消息推送
  pushEdit = () => import('@/views/msgPush/push-edit'),
  pushHistory = () => import('@/views/msgPush/push-history'),
  pushSetting = () => import('@/views/msgPush/push-setting'),
  historyDetail = () => import('@/views/msgPush/history-detail'),

  role = () => import('@/views/syssetting/role/role'),
  roleEdit = () => import('@/views/syssetting/role/roleEdit'),
  group = () => import('@/views/syssetting/group/group'),
  manager = () => import('@/views/syssetting/manager/manager'),
  managerEdit = () => import('@/views/syssetting/manager/managerEdit'),
  log = () => import('@/views/syssetting/log'),
  configList = () => import('@/views/syssetting/config/config'),
  configEdit = () => import('@/views/syssetting/config/configEdit'),
  subSystem = () => import('@/views/subSystem'),
  branch = () => import('@/views/companyStaff/branch'),
  staff = () => import('@/views/companyStaff/staff'),
  userCenter = () => import('@/views/user/user'),
  password = () => import('@/views/user/password'),
  // 行政区划设置
  // pLocation = () => import('@/views/syssetting/p-location'),
  // 微信登录
  wechat = () => import('@/views/syssetting/wechat'),
  // 敏感词审核设置
  sensitive = () => import('@/views/syssetting/sensitive/sensitive'),
  // 新增审核对象
  addAudit = () => import('@/views/syssetting/sensitive/add-audit'),
  //支付管理
  billManage = () => import('@/views/payoff/account-manage'),
  //提现管理
  // extractManage = () => import('@/views/payoff/extract-manage'),
  extractManage = () => import('@/views/payoff/extact-manages'),
  // 可提现金额
  withdrawableAmount = () => import('@/views/payoff/withdrawable-amount'),

  //资金对账
  // fundManage=()=>import('@/views/payoff/fund-manage'),
  //对账详情
  fundDetail = () => import('@/views/payoff/fund-detail'),
  /*提交规则*/
  depossitRules = () => import('@/views/payoff/deposit-rule'),
  /*S端*/
  sportSystem = () => import('../views/sportSystem'),
  // 机构授权信息详情
  accountApply = () => import('../views/account/accountApply'),

  practice = () => import("@/views/practice/practice"),

  // 机构组列表页面
  accountGroup = () => import('@/views/account/account-group'),

  // 增值服务
  serviceOrder = () => import('@/views/service-order/service-order'),  
  serviceOrderMerchants = () => import('@/views/service-order/service-order-merchants'),
  // 消费记录
  expenseRecord = () => import('@/views/expense-record/expense-record');

// //机构应用授权列表
// applyCofnig = () => import('../views/account/apply-config');
// 2020.12.07 支付设置 - 账号绑定
const PaySet = () => import("../views/payoff/pay-set");
const generalSettings = () => import('@/views/syssetting/general-settings');
//增值服务
const addedServices = () => import ("../views/account/components/added-services");
const applyConfig = () => import("../views/account/apply-config");
const constrouter = [
  // {
  //   path: "/",
  //   name: "",
  //   component: layout,
  //   redirect: "/account",
  //   meta: {
  //     menuId: "0",
  //   },
  //   children: [

  //   ],
  // },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      title: "登录",
    }
  },
  {
    path: '/',
    name: 'account',
    component: layout,
    menuId: '1',
    redirect: '/account',
    children: [
      {
        path: "matrix",
        name: "matrix",
        component: matrix,
        meta: {
          title: "子网系统管理",
          keepAlive: true,
          icon: "icon-pc",
          hideSource: true,
          menuId: "01",
        },
      },
      {
        path: "subSystem/:id",
        name: "subSystem",
        component: subSystem,
        meta: {
          menuId: "3",
          title: "应用",
        },
      },
      {
        path: "sportSystem/",
        name: "sportSystem",
        component: sportSystem,
        meta: {
          menuId: "4",
          title: "S端",
        },
      },
      //首页
      // {
      //     path: 'home',
      //     name: '首页',
      //     component: Home,
      //     meta: {
      //         menuId: '999',
      //         title: '首页',
      //         icon: 'icon-userSet',
      //     }
      // },
      {
        path: "account/accountGroup",
        name: "accountGroup",
        component: accountGroup,
        hidden: true,
        meta: {
          menuId: '11',
          title: '机构管理-机构组设置'
        }
      },
      {
        path: 'account',
        name: 'account',
        component: accountList,
        meta: {
          title: '机构管理',
          icon: 'icon-userSet',
          menuId: '11',
          keepAlive: true,
          add: 'business_user_create',
          edit: 'business_user_update',
          del: 'business_user_delete',
          stop: 'business_user_stop',
          reset: 'business_user_reset'
        }
      },
      //增值服务2023-9-23
      {
        path: '/added-services/:id',
        name: 'addedServices',
        component: addedServices,
        meta: {
          title: '机构管理-增值服务',
          icon: 'icon-userSet',
          menuId: '11',
          keepAlive: true,
        }
      },
      //增值服务2023-9-23
      {
        path: '/apply-config/:id',
        name: 'applyConfig',
        component: applyConfig,
        meta: {
          title: '机构管理-应用配置',
          icon: 'icon-userSet',
          menuId: '11',
          keepAlive: true,
        }
      },
      {
        path: "practice",
        name: "practice",
        component: practice,
        meta: {
          menuId: "22",
          title: "新时代文明实践",
        }
      },
      {
        path: 'account/accountType',
        name: 'accountType',
        component: accountType,
        hidden: true,
        meta: {
          menuId: '11',
          title: '机构管理-选择机构类型'
        }
      },
      {
        path: 'account/accountChoose',
        name: 'accountChoose',
        component: accountChoose,
        hidden: true,
        meta: {
          menuId: '11',
          title: '机构管理-选择机构类型'
        }
      },
      //机构管理
      {
        path: 'accountorganizational/edit/:id',
        name: 'accountorganizational',
        component: accountOrganizational,
        hidden: true,
        meta: {
          menuId: '11',
          title: '机构管理-编辑',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'accountorganizational/edit/:id?from=query',
        name: 'accountorganizational',
        component: accountOrganizational,
        hidden: true,
        meta: {
          menuId: '11',
          title: '机构管理-查看',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'accountorganizational/edit',
        name: 'accountorganizational',
        component: accountOrganizational,
        hidden: true,
        meta: {
          menuId: '11',
          title: '机构管理-新增',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      //文明实践活动
      {
        path: 'practiceOrganizational/edit/:id',
        name: 'practiceOrganizational',
        component: practiceOrganizational,
        hidden: true,
        meta: {
          menuId: '11',
          title: '机构管理-编辑',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'practiceOrganizational/edit/:id?from=query',
        name: 'practiceOrganizational',
        component: practiceOrganizational,
        hidden: true,
        meta: {
          menuId: '11',
          title: '机构管理-查看',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'practiceOrganizational/edit',
        name: 'practiceOrganizational',
        component: practiceOrganizational,
        hidden: true,
        meta: {
          menuId: '11',
          title: '机构管理-新增',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      //新闻机构
      {
        path: 'accountOrg/edit/:id',
        name: 'accountOrgEdit',
        component: accountOrgEdit,
        hidden: true,
        meta: {
          menuId: '11',
          title: '机构管理-编辑',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'accountOrg/edit/:id?from=query',
        name: 'accountOrgEdit',
        component: accountOrgEdit,
        hidden: true,
        meta: {
          menuId: '11',
          title: '机构管理-查看',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'accountOrg/edit',
        name: 'accountOrgEdit',
        component: accountOrgEdit,
        hidden: true,
        meta: {
          menuId: '11',
          title: '机构管理-新增',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'accountApply/:id',
        name: 'accountApply',
        component: accountApply,
        hidden: true,
        meta: {
          menuId: '11',
          title: '机构管理- 授权信息',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'accountPer/edit/:id',
        name: 'accountPerEdit',
        component: accountPerEdit,
        hidden: true,
        meta: {
          menuId: '11',
          title: '机构管理-主播-新增',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'accountPer/edit',
        name: 'accountPerEdit',
        component: accountPerEdit,
        hidden: true,
        meta: {
          menuId: '11',
          title: '机构管理-主播-新增',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'account-bill',
        name: 'accountBill',
        component: accountBill,
        hidden: true,
        meta: {
          menuId: '12',
          title: '机构计费中心',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'org-account',
        name: 'orgAccount',
        component: orgAccount,
        hidden: true,
        meta: {
          menuId: '24',
          title: '机构计费中心',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'account-detail/:id',
        name: 'accountBillDetail',
        component: accountBillDetail,
        hidden: true,
        meta: {
          menuId: '12',
          title: '机构计费详情',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'new-account-detail',
        name: 'newAccountBillDetail',
        component: newAccountBillDetail,
        hidden: true,
        meta: {
          menuId: '12',
          title: '新机构计费详情',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'account-renew/:id',
        name: 'accountRenew',
        component: accountRenew,
        hidden: true,
        meta: {
          menuId: '12',
          title: '机构计费中心续费',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'account-order',
        name: 'accountOrder',
        component: accountOrder,
        hidden: true,
        meta: {
          menuId: '13',
          title: '机构订单管理',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'account-bill-manage',
        name: 'billManage',
        component: billManage,
        meta: {
          menuId: '14',
          title: '账单管理',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'pay-set',
        name: 'paySet',
        component: PaySet,
        meta: {
          menuId: '14',
          title: '支付设置',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'extract-manage',
        name: 'extractManage',
        component: extractManage,
        meta: {
          menuId: '16',
          title: '提现管理',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      {
        path: 'withdrawable-amount',
        name: 'withdrawableAmount',
        component: withdrawableAmount,
        meta: {
          menuId: '16',
          title: '可提现金额',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },
      /*{
        path: 'account-fund-manage',
        name: 'fundManage',
        component: fundManage,
        meta: {
          menuId: '15',
          title: '支付宝资金对账',
          pageType: 'edit',
          keepAlive: false,
          add: 'business_user_create',
          edit: 'business_user_update'
        }
      },*/
      {
        path: "account-fund-detail",
        name: "fundDetail",
        component: fundDetail,
        hidden: true,
        meta: {
          menuId: "15",
          title: "资金对账明细",
          pageType: "edit",
          keepAlive: false,
          add: "business_user_create",
          edit: "business_user_update",
        },
      },
      {
        path: "depossit-rules",
        name: "depossitRules",
        component: depossitRules,
        hidden: true,
        meta: {
          menuId: "42",
          title: "提现规则",
          pageType: "edit",
          keepAlive: false,
          add: "business_user_create",
          edit: "business_user_update",
        },
      },
      {
        path: "order-create",
        name: "accountOrderEdit",
        component: accountOrderEdit,
        hidden: true,
        meta: {
          menuId: "13",
          title: "订单新建",
          pageType: "edit",
          keepAlive: false,
          add: "business_user_create",
          edit: "business_user_update",
        },
      },
      {
        path: "order-detail/:id",
        name: "accountOrderDetail",
        component: accountOrderDetail,
        hidden: true,
        meta: {
          menuId: "13",
          title: "订单详情",
          pageType: "edit",
          keepAlive: false,
          add: "business_user_create",
          edit: "business_user_update",
        },
      },
      {
        path: "message",
        name: "message",
        component: message,
        hidden: true,
        meta: {
          menuId: "41",
          title: "消息管理",
          pageType: "edit",
          keepAlive: false,
          add: "business_user_create",
          edit: "business_user_update",
        },
      },
      {
        path: "push-edit",
        name: "push-edit",
        component: pushEdit,
        hidden: true,
        meta: {
          menuId: "990",
          title: "推送通知",
          pageType: "edit",
          keepAlive: false,
          add: "business_user_create",
          edit: "business_user_update",
        },
      },
      {
        path: "push-history",
        name: "push-history",
        component: pushHistory,
        hidden: true,
        meta: {
          menuId: "990",
          title: "推送历史",
          pageType: "edit",
          keepAlive: false,
          add: "business_user_create",
          edit: "business_user_update",
        },
      },
      {
        path: "/history-detail/:id",
        component: historyDetail,
        name: "historyDetail",
        meta: {
          title: "记录详情",
          menuId: "54",
          keepAlive: false,
          add: "business_user_create",
          edit: "business_user_update",
        },
      },
      {
        path: "push-setting",
        name: "push-setting",
        component: pushSetting,
        hidden: true,
        meta: {
          menuId: "53",
          title: "推送配置",
          pageType: "edit",
          keepAlive: false,
          add: "business_user_create",
          edit: "business_user_update",
        },
      },
      {
        path: 'service-order',
        name: 'serviceOrder',
        component: serviceOrder,
        hidden: true,
        meta: {
          menuId: '54',
          title: '增值服务',
          keepAlive: false,
        }
      },
      {
        path: 'service-order/merchants',
        name: 'serviceOrderMerchants',
        component: serviceOrderMerchants,
        hidden: true,
        meta: {
          menuId: '54',
          title: '机构服务情况',
          keepAlive: false,
        }
      },
      {
        path: 'expense-record/:id',
        name: 'expenseRecord',
        component: expenseRecord,
        hidden: true,
        meta: {
          menuId: '',
          title: '消费记录',
          keepAlive: false,
        }
      },
    ],
  },
  {
    path: "/sysSetting",
    component: layout,
    name: "系统设置",
    redirect: "/sysSetting/group",
    meta: {
      title: "系统设置",
      icon: "icon-setting",
      menuId: "2",
    },
    // redirect: 'noredirect',
    alwaysShow: true, // will always show the root menu
    children: [
      {
        path: "role",
        component: role,
        name: "role",
        meta: {
          title: "角色管理",
          icon: "icon-role",
          keepAlive: true,
          menuId: "21",
          add: "role_create",
          edit: "role_update",
          del: "role_delete",
        },
      },
      {
        path: "role/edit/:id",
        component: roleEdit,
        name: "roleEdit",
        hidden: true,
        meta: {
          title: "角色管理",
          icon: "icon-role",
          menuId: "21",
          keepAlive: false,
          pageType: "edit",
          add: "role_create",
          edit: "role_update",
        },
      },
      {
        path: "role/edit",
        component: roleEdit,
        name: "roleEdit",
        hidden: true,
        meta: {
          title: "角色管理",
          icon: "icon-role",
          menuId: "21",
          keepAlive: false,
          pageType: "edit",
          add: "role_create",
          edit: "role_update",
        },
      },
      {
        path: "group",
        component: group,
        name: "group",
        meta: {
          title: "组织管理",
          icon: "icon-group",
          keepAlive: true,
          menuId: "25",
          add: "group_create",
          edit: "group_update",
          del: "group_delete",
        },
      },
      {
        path: "manager",
        component: manager,
        name: " manager",
        meta: {
          title: "管理员管理",
          icon: "icon-manager",
          menuId: "22",
          keepAlive: true,
          add: "user_create",
          edit: "user_update",
          del: "user_delete",
          stop: "user_stop",
          reset: "user_reset",
        },
      },
      {
        path: "manager/edit/:id",
        component: managerEdit,
        name: "managerEdit",
        hidden: true,
        meta: {
          title: "管理员管理-编辑",
          keepAlive: false,
          menuId: "22",
          pageType: "edit",
          add: "user_create",
          edit: "user_update",
        },
      },
      {
        path: "manager/edit",
        component: managerEdit,
        name: "managerAdd",
        hidden: true,
        meta: {
          title: "管理员管理-新增",
          keepAlive: false,
          menuId: "22",
          pageType: "edit",
          add: "user_create",
          edit: "user_update",
        },
      },
      //   {
      //     path: 'log',
      //     component: log,
      //     name: 'log',
      //     meta: {
      //       title: '操作日志',
      //       icon: 'icon-log',
      //       keepAlive: false,
      //       menuId: '23',
      //       del: 'log_delete',
      //     },
      //   },
      {
        path: "config",
        component: configList,
        name: "config",
        meta: {
          title: "应用系统配置管理",
          icon: "icon-config",
          menuId: "23",
          keepAlive: true,
          add: "subsystem_create",
          edit: "subsystem_update",
          del: "subsystem_delete",
          stop: "subsystem_stop",
        },
      },
      {
        path: "config/edit/:id",
        component: configEdit,
        name: "configEdit",
        meta: {
          title: "应用系统配置管理-编辑",
          icon: "icon-config",
          keepAlive: false,
          menuId: "23",
          pageType: "edit",
          add: "subsystem_create",
          edit: "subsystem_update",
        },
        hidden: true,
      },
      {
        path: "config/edit",
        component: configEdit,
        name: "configAdd",
        meta: {
          title: "应用系统配置管理-新增",
          icon: "icon-config",
          keepAlive: false,
          menuId: "23",
          pageType: "edit",
          add: "subsystem_create",
          edit: "subsystem_update",
        },
        hidden: true,
      },
      {
        path: "login-record",
        component: loginRecord,
        name: "loginRecord",
        meta: {
          title: "用户登录记录",
          icon: "icon-config",
          keepAlive: false,
          menuId: "26",
          pageType: "edit",
          add: "subsystem_create",
          edit: "subsystem_update",
        },
        hidden: true,
      },
      // {
      //     path: 'p-location',
      //     component: pLocation,
      //     name: 'pLocation',
      //     meta: {
      //         title: '行政区划设置',
      //         icon: 'icon-config',
      //         menuId: '29',
      //         keepAlive: false
      //     },
      // },
      // {
      //   path: "wechat",
      //   component: wechat,
      //   name: "wechat",
      //   meta: {
      //     title: "微信登录",
      //     icon: "icon-config",
      //     menuId: "201",
      //     keepAlive: false,
      //   },
      // },
      {
        path: "general-settings",
        component: generalSettings,
        name: "generalSettings",
        meta: {
          title: "通用设置",
          icon: "icon-config",
          menuId: "201",
          keepAlive: false,
        },
      },
      {
        path: "sensitive",
        component: sensitive,
        name: "sensitive",
        meta: {
          title: "敏感词审核设置",
          icon: "icon-config",
          menuId: "202",
          keepAlive: false,
        },
      },
      {
        path: "add-audit",
        component: addAudit,
        name: "add-audit",
        meta: {
          title: "新增审核对象",
          icon: "icon-config",
          menuId: "202",
          keepAlive: false,
        },
      },
      ...routerW,
    ],
  },
  //   {
  //     path: '/companyStaff',
  //     component: layout,
  //     name: '',
  //     redirect: 'noredirect',
  //     meta: {
  //       title: '公司员工管理',
  //       icon: 'icon-companyStaff',
  //       menuId: '3',
  //     },
  //     alwaysShow: true, // will always show the root menu
  //     children: [{
  //         path: 'branch',
  //         component: branch,
  //         name: 'branch',
  //         meta: {
  //           title: '部门管理',
  //           icon: 'icon-branch',
  //           keepAlive: false,
  //           menuId: '31',
  //           add: 'department_create',
  //           edit: 'department_update',
  //           del: 'department_delete',
  //         },
  //       },
  //       {
  //         path: 'staff',
  //         component: staff,
  //         name: '',
  //         meta: {
  //           title: '人员管理',
  //           icon: 'icon-staff',
  //           menuId: '32',
  //           keepAlive: false,
  //           add: 'employee_create',
  //           edit: 'employee_update',
  //           del: 'employee_delete',
  //         },
  //       }
  //     ]
  //   },
  {
    path: "",
    component: layout,
    name: "user",
    hidden: true,
    children: [
      {
        path: "userCenter",
        component: userCenter,
        name: "userCenter",
        meta: {
          title: "个人中心",
        },
      },
      {
        path: "password",
        component: password,
        name: "password",
        meta: {
          title: "修改密码",
        },
      },
    ],
  },
  {
    path: "*",
    component: () => import("@/views/errorPage/404"),
    hidden: true,
  },
];

//判断是否有权限
function iscanEnter(name) {
  let permissions = JSON.parse(localStorage.getItem("userInfo")).permissions;
  console.log("permissions", permissions);
  if (permissions && permissions.length) {
    let arr = [];
    switch (name) {
      // 机构管理
      case "account":
      case "accountOrgEdit":
        // case '/accountOrg/edit/?from=query'
        arr = ["p_sys_client_all", "p_sys_client_modify", "p_sys_client_view"];
        break;
      // case '/account/account-edit':
      case "accountPerEdit":

      case "account/accountChoose":
      case "accountType":
        arr = ["p_sys_client_all", "p_sys_client_modify"];
        break;
      //  机构计费中心
      case "accountBill":
      case "accountBillDetail":
      case "accountRenew":
        arr = ["p_emc_fee_all"];
        break;
      //  机构订单管理
      case "accountOrder":
      case "accountOrderDetail":
        arr = ["p_emc_clientorder_all", "p_emc_clientorder_view"];
        break;
      case "accountOrderEdit":
        arr = ["p_emc_clientorder_all"];
        break;
      //  应用系统配置管理
      case "configList":
      case "configEdit":
        arr = ["p_sys_subsystem_all"];
        break;
      //  登录日志
      case "loginRecord":
        arr = ["p_sys_log_all"];
        break;
      //  管理员管理模块
      case "manager":
        arr = ["p_sys_admin_all", "p_sys_admin_modify", "p_sys_admin_view"];
        break;
      case "managerEdit":
        arr = ["p_sys_admin_all", "p_sys_admin_modify"];
        break;
      //  用户管理模块
      case "role":
        arr = ["p_sys_role_all", "p_sys_role_modify", "p_sys_role_view"];
        break;
      case "roleEdit":
        arr = ["p_sys_role_all", "p_sys_role_modify"];
        break;
      //  组织管理模块
      case "group":
        arr = ["p_sys_group_all", "p_sys_group_modify", "p_sys_group_view"];
        break;
      //  C端用户管理
      case "customer":
        arr = ["p_sys_user_all", "p_sys_user_view"];
        break;
      // 增值服务
      case "serviceOrder":
        arr = ["p_emc_clientorder_all", "p_emc_clientorder_view"];
        break;
      default:
        arr = [];
        break;
    }
    return judgePath(permissions, arr);
  } else {
    return false;
  }
}

function judgePath(permissions, arr) {
  let isHas = false;
  if (arr.length) {
    for (let i = 0; i < arr.length; i++) {
      let index = permissions.findIndex((ele) => {
        return arr[i] == ele.real_name;
      });
      if (index != -1) {
        isHas = true;
        break;
      }
    }
    return isHas;
  } else {
    return true;
  }
}

//进入需要权限的页面
function isEnterSetting(name) {
  const settingRoutes = [
    //组织
    "group",
    //角色
    "role",
    "roleEdit",
    //管理员
    "manager",
    "managerEdit",
    //日志
    "loginRecord",
    //权限
    "auth",
    //子网
    "configList",
    "configEdit",
    //C端用户管理
    "customer",
    //机构订单
    "accountOrderEdit",
    "accountOrderDetail",
    "accountOrder",
    //机构中心计费
    "accountRenew",
    "accountBillDetail",
    "accountBill",
    //机构管理
    "accountPerEdit",
    "accountOrgEdit",
    "account/accountChoose",
    "accountType",
    "account",
  ];
  return settingRoutes.includes(name);
}

const router = new Router({
  base: '/',
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
    };
  },
  mode: "history",
  routes: constrouter,
});
store.commit("SET_SIDERNAV", constrouter);
router.beforeEach(async(to, from, next) => {
  console.log('to :>> ', to);
  console.log('to.query.code :>> ', to.query.code);
  console.log("from", from);
  if(to.query.code && window.location.href.indexOf("login") != -1){
    console.log('to.query.code1111 :>> ', to.query.code);
    try {
      // sessionStorage.setItem("code", to.query.code);
      const params = {
        code: to.query.code
      }
      const res = await getWxUserInfo(params);
          if(res.code == 100000){
            const wxdata = {
              app_type: 0,      //第三方类型，0为微信登录，4为苹果账号
              type: 3,   //app_type为0时必须，微信登录端 1:公众号，2:APP，3:PC
              client_type: "p",   //登录端，p，b，c
              unionid: res.data.unionid,
              nickname: res.data.nickname,
              headimgurl: res.data.headimgurl,
              openid: res.data.openid,
              has_refresh_token: 0,    //是否需要刷新token,给app用的，前端给0就好
            }
            const {data, code} = await wxLogin(wxdata);
            if(code === 100000) {
              cookie.set(
                "Authorization",
                data.auth.authorization,
                2
              );

              console.log('data.auth.password_day, :>> ', data.auth.password_day,data.auth.password_prompt);
              if(data.auth.password_prompt && data.auth.password_day){
                next(`/account?prompt=${data.auth.password_day}`)
              }else{
                next('/account')
              }
            } else if(code === 302001){
              next('/login')
              // alert(用户权限不足)
            }else{
              next('/login')
            }
          }else {
            console.log('res.msg :>> ', res.msg);
          }
      // const wxInfo = await getWxUserInfo(params);
      // console.log('wxInfo111 :>> ', wxInfo);

    }catch(e) {
      console.log('getWXCode :>> ', e);
      next('/login')
    }
  //  return
  }else if(to.query.code){
    next(`/userCenter?wxcode=${to.query.code}`)
  }
  // return
  NProgress.start(); // start progress bar
  if (to.query.redirect) {
    sessionStorage.setItem("redirect", to.query.redirect);
  }
  /**
   *
   * @author Lynn
   * @param pageType 路由 meta里面定义的类型，如果存在该字段说明为编辑或者新增页面
   * @description 提取sessionStorage存取的权限数据，如果不能在再次请求接口获取权限。如果是超级用户直接进入下一级路由。
   *              meta里面区分是add（新增）和edit（编辑） 如果query.id不为空那么就是编辑。条件不成立直接进入404
   */
  console.log("title", to.meta.title);
  if(!window.__POWERED_BY_QIANKUN__) {
    document.title = to.meta.title;
  }
  if (to.path == "/login") {
    //前往登录页
    console.log("登录");
    next();
  } else {
    //非登录页
    let tk = cookie.get("Authorization");
    // console.log('router',tk,localStorage.getItem('userInfo'));
    if (tk) {
      //Authorization存在
      if (localStorage.getItem("needLogout")) {
        //需要重新登录
        next("/login");
      } else {
        let userInfo = localStorage.getItem("userInfo");
        if (userInfo) {
          //用户信息存在，且Authorization参数与cookie相同
          console.log("in local userinfo");
          userInfo = JSON.parse(userInfo);
          getPersonalInfo().then((res) => {
            if (res.code == "100000") {
              userInfo = res.data;
              store.commit("SET_USERINFO", {
                userInfo,
              });
              // 每个用户账号第一次登录时需要强制修改密码
              if (userInfo.user && userInfo.user.password_checked == 0) {
                //没有修改过密码
                if (to.path === "/password") {
                  //前往修改密码页
                  if(!window.__POWERED_BY_QIANKUN__) {
                    document.title = `${to.meta.title}` || "用户管理中心";
                  }
                  next();
                } else {
                  // 用户尚未修改过密码
                  Notification.info({
                    title: "请修改密码",
                    message: "初次登录，请您先修改密码",
                  });
                  next("/password");
                }
              } else {
                //修改过密码
                // if (userInfo.user_p && userInfo.user_p.is_super_admin != 1 && isEnterSetting(to.path)) {//不是超管，且进入了需要权限的页面
                //   Notification.info({
                //     title: '无权限',
                //     message: '您没有进入系统设置模块的权限'
                //   });
                //   next('/account');
                // }
                // else {
                //   document.title = `${to.meta.title}` || '用户管理中心';
                //   next();
                // }
                //如果不是管理员
                if (
                  userInfo &&
                  userInfo.is_admin != 1 &&
                  isEnterSetting(to.name) &&
                  !iscanEnter(to.name)
                ) {
                  console.log("不是管理员1111", to);
                  // 不是超管并且进入了权限控制页面
                  let msg = "";
                  switch (to.name) {
                    case "account":
                      msg = "机构管理查看";
                      break;
                    case "accountPerEdit":
                    case "accountOrgEdit":
                    case "account/accountChoose":
                    case "accountType":
                      msg = "机构管理修改";
                      break;
                    case "accountBill":
                    case "accountBillDetail":
                    case "accountRenew":
                      msg = "机构计费中心";
                      break;
                    case "accountOrder":
                    case "accountOrderDetail":
                      msg = "机构订单管理";
                      break;
                    case "accountOrderEdit":
                      msg = "机构订单管理修改";
                      break;
                    case "loginRecord":
                      msg = "登录日志";
                      break;
                    case "configList":
                      msg = "应用系统配置管理";
                      break;
                    case "configEdit":
                      msg = "应用系统配置管理修改";
                      break;
                    case "manager":
                      msg = "管理员管理";
                      break;
                    case "managerEdit":
                      msg = "管理员管理修改";
                      break;
                    case "role":
                      msg = "角色管理";
                      break;
                    case "roleEdit":
                      msg = "角色管理修改";
                      break;
                    case "group":
                      msg = "组织管理";
                      break;
                    case "customer",
                          "customerEdit":
                      msg = "用户中心管理";
                      break;
                  }
                  Notification.info({
                    title: "无权限",
                    message: `您没有进入${msg}模块的权限`,
                  });
                  return next("/nopermission");
                } else {
                  if (userInfo && userInfo.is_admin != 1) {
                    //不是管理员
                    let index = -1;
                    console.log("name----", to.name);
                    if (to.name == "accountOrgEdit") {
                      console.log("进入编辑页面");
                      let arr = ["p_sys_client_modify", "p_sys_client_all"];
                      let isHas = false;
                      for (let i = 0; i < arr.length; i++) {
                        index = userInfo.permissions.findIndex((ele) => {
                          return arr[i] == ele.real_name;
                        });
                        if (index != -1) {
                          isHas = true;
                          break;
                        }
                      }
                      if (isHas == true) {
                        console.log("有修改/全部权限");
                        next();
                      } else {
                        if (to.query.from == "query") {
                          next();
                        } else {
                          return next({
                            path: to.path,
                            query: {
                              from: "query",
                            },
                          });
                        }
                        console.log("无修改");

                        // return next('/account-bill')
                      }
                    } else if (to.path === "/login") {
                      return next("/account");
                    } else {
                      if(!window.__POWERED_BY_QIANKUN__) {
                        document.title = `${to.meta.title}` || "用户管理中心";
                      }
                      return next();
                    }
                  } else {
                    //是管理员
                    if (to.path === "/login") {
                      return next("/account");
                    } else {
                      if(!window.__POWERED_BY_QIANKUN__) {
                        document.title = `${to.meta.title}` || "用户管理中心";
                      }
                      return next();
                    }
                  }
                }
              }
            }
          });
        } else {
          console.log("in get userinfo");
          getPersonalInfo().then((res) => {
            let userInfo = res.data;
            store.commit("SET_USERINFO", {
              userInfo,
            });
            // 每个用户账号第一次登录时需要强制修改密码
            if (userInfo.user && userInfo.user.password_checked == 0) {
              //没有修改过密码
              if (to.path === "/password") {
                //前往修改密码页
                if(!window.__POWERED_BY_QIANKUN__) {
                  document.title = `${to.meta.title}` || "用户管理中心";
                }
                next();
              } else {
                // 用户尚未修改过密码
                Notification.info({
                  title: "请修改密码",
                  message: "初次登录，请您先修改密码",
                });
                next("/password");
              }
            } else {
              //修改过密码
              //如果不是管理员
              if (
                userInfo &&
                userInfo.is_admin != 1 &&
                isEnterSetting(to.name) &&
                !iscanEnter(to.name)
              ) {
                console.log("不是管理员");
                // 不是超管并且进入了权限控制页面
                let msg = "";
                switch (to.name) {
                  case "account":
                    msg = "机构管理查看";
                    break;
                  case "accountPerEdit":
                  case "accountOrgEdit":
                  case "account/accountChoose":
                  case "accountType":
                    msg = "机构管理修改";
                    break;
                  case "accountBill":
                  case "accountBillDetail":
                  case "accountRenew":
                    msg = "机构计费中心";
                    break;
                  case "accountOrder":
                  case "accountOrderDetail":
                    msg = "机构订单管理";
                    break;
                  case "accountOrderEdit":
                    msg = "机构订单管理修改";
                    break;
                  case "loginRecord":
                    msg = "登录日志";
                    break;
                  case "configList":
                    msg = "应用系统配置管理";
                    break;
                  case "configEdit":
                    msg = "应用系统配置管理修改";
                    break;
                  case "manager":
                    msg = "管理员管理";
                    break;
                  case "managerEdit":
                    msg = "管理员管理修改";
                    break;
                  case "role":
                    msg = "角色管理";
                    break;
                  case "roleEdit":
                    msg = "角色管理修改";
                    break;
                  case "group":
                    msg = "组织管理";
                    break;
                  case "customer",
                        "customerEdit":
                    msg = "用户中心管理";
                    break;
                }
                Notification.info({
                  title: "无权限",
                  message: `您没有进入${msg}模块的权限`,
                });
                return next("/nopermission");
              } else {
                if (userInfo && userInfo.is_admin != 1) {
                  //不是管理员
                  let index = -1;
                  console.log("name----", to.name);
                  if (to.name == "accountOrgEdit") {
                    console.log("进入编辑页面");
                    let arr = ["p_sys_client_modify", "p_sys_client_all"];
                    let isHas = false;
                    for (let i = 0; i < arr.length; i++) {
                      index = userInfo.permissions.findIndex((ele) => {
                        return arr[i] == ele.real_name;
                      });
                      if (index != -1) {
                        isHas = true;
                        break;
                      }
                    }
                    if (isHas == true) {
                      console.log("有修改/全部权限");
                      next();
                    } else {
                      if (to.query.from == "query") {
                        next();
                      } else {
                        return next({
                          path: to.path,
                          query: {
                            from: "query",
                          },
                        });
                      }
                      console.log("无修改");

                      // return next('/account-bill')
                    }
                  } else if (to.path === "/login") {
                    return next("/account");
                  } else {
                    if(!window.__POWERED_BY_QIANKUN__) {
                      document.title = `${to.meta.title}` || "用户管理中心";
                    }
                    return next();
                  }
                } else {
                  //是管理员
                  if (to.path === "/login") {
                    return next("/account");
                  } else {
                    if(!window.__POWERED_BY_QIANKUN__) {
                      document.title = `${to.meta.title}` || "用户管理中心";
                    }
                    return next();
                  }
                }
              }
            }
          });
        }
      }
    } else {
      //Authorization不存在
      next("/login");
    }
  }
});
//处理在生产环境中，如何避免浏览器缓存Webpack 3 编译后生成的js路径
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

/**
 * 跳转方式判断
 * @param Object redirectPath 重定向信息
 * @param function next vue-router 路由守卫中的function ,该方法来 resolve 这个钩子
 */
function nextPage(redirectPath, next) {
  if (redirectPath && redirectPath.is_redirect) {
    //如果是需要重定向
    localStorage.setItem(
      "fromPath",
      JSON.stringify({
        is_redirect: false,
      })
    );
    next({
      path: redirectPath.path,
      replace: true,
    }); //进入重定向的地址
  } else {
    next();
  }
}

router.afterEach(() => {
  NProgress.done(); // finish progress bar
});

export default router;
