<template>
  <div class="source">
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <template v-for='(item,index) in levelList' >
        <el-breadcrumb-item :key="index" v-if='item.meta.title'>
            <a href="javascript:;" v-if='item.redirect==="noredirect"||index==levelList.length-1' class="no-redirect">{{item.meta.title}}</a>
            <router-link v-else :to="item.redirect||item.path">{{item.meta.title}}</router-link>
        </el-breadcrumb-item>
        </template>
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
  name: "breadcrumb",
  data() {
    return {
      levelList: null
    };
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched.filter(item => item.meta.title);
      const first = matched[0];
      if (first && first.meta.title !== "") {
        matched = [{ path: "/", meta: { title: "首页" } }].concat(matched);
      }
      this.levelList = matched;
      console.log('this.levelList',this.levelList)
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    }
  }
};
</script>
<style>
  .no-redirect {
      color: #97a8be;
      cursor: text;
    }
</style>


