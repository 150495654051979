import { getPermissions } from "@/api/user";
const permission = {
  state: {
    permission: {
      admin: 0,
      data: []
    },
    currentPage: {

    },
    siderbar:[]
  },
  getters:{
    permission_routers: state => {
      return state.siderbar;
    }
  },
  mutations: {
    SET_PERMIS: (state, row) => {
      state.permission = row;
      sessionStorage.setItem('buttenpremissions', JSON.stringify(res))
    },
    SET_PAGE: (state, row) => {
      state.currentPage = row;
    },
    SET_SIDERNAV:(state, row)=>{
      state.siderbar=row
    }
  },
  actions: {
    checkPermis() {
      getPermissions().then(res => {
        commit('SET_PERMIS', res)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}

export default permission
