// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './public-path';
import Vue from 'vue'
import App from './App'
import actions from './actions'
// import router from './router/index'
// import 'babel-polyfill'
import ElementUI from 'element-ui'
import { Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import store from '@/store/index.js'
import hasDirective from '@/utils/directive'
import './assets/sass/main.scss'
import VueAreaLinkage from 'vue-area-linkage'
import 'vue-area-linkage/dist/index'

import VueLazy from 'vue-lazyload';
import placeholderImg from '@/assets/image/sys_config/place_config.png';

import moment from 'moment' // 导入文件

Vue.use(VueLazy, {
  loading: placeholderImg,
  error: placeholderImg
})

Vue.prototype.$moment = moment //赋值使用moment

Vue.prototype.HOST = process.env.NODE_ENV === "development" ? '/api' : ''
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueAreaLinkage)

// 隐藏手机号中间4位
Vue.directive('hdphone', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function (el) {
    if (!el.innerText) {
      return;
    }
    // 聚焦元素
    // el.focus()
    let phone = el.innerText;
    var reg = /^(\d{3})\d{4}(\d{4})$/;
    phone = phone.replace(reg, "$1****$2");
    el.innerText = phone;
  },
  // 当被绑定的元素插入到 DOM 中时……
  componentUpdated: function (el) {
    if (!el.innerText) {
      return;
    }
    // 聚焦元素
    // el.focus()
    let phone = el.innerText;
    var reg = /^(\d{3})\d{4}(\d{4})$/;
    phone = phone.replace(reg, "$1****$2");
    el.innerText = phone;
  }
});
// 身份证号脱敏处理，前3位和后4位显示数字，其他都替换成“*”
Vue.directive('hdsfz', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function (el) {
    if (!el.innerText) {
      return;
    }
    // 聚焦元素
    // el.focus()
    let sfz = el.innerText;
    var reg = /^(\d{3})\d{11}(\d{4})$/;
    sfz = sfz.replace(reg, "$1***********$2");
    el.innerText = sfz;
  },
  // 当被绑定的元素插入到 DOM 中时……
  componentUpdated: function (el) {
    if (!el.innerText) {
      return;
    }
    // 聚焦元素
    // el.focus()
    let sfz = el.innerText;
    var reg = /^(\d{3})\d{11}(\d{4})$/;
    sfz = sfz.replace(reg, "$1***********$2");
    el.innerText = sfz;
  }
})


// 引入公共函数
import { throttle } from './utils/utils.js';
const $g = {
  throttle
};
Vue.prototype.$g = $g;

let instance = null;
let router = null;
function render(props = {}) {
  if (props) {
    // 注入 actions 实例
    actions.setActions(props);
  }
  const {
    container
  } = props;
  router = require('./router/index').default
  if (window.__POWERED_BY_QIANKUN__) {
    router.options.base = props.base;
    router.history.base = props.base;
  }
  instance = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app');
}

Vue.prototype.__POWERED_BY_QIANKUN__ = window.__POWERED_BY_QIANKUN__
// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

export async function bootstrap() {
  console.log('[vue] vue app bootstraped');
}
export async function mount(props) {
  console.log('[vue] props from main framework bbb', props);
  render(props);
}
export async function unmount() {
  Message.closeAll();
  instance.$destroy();
  instance.$el.innerHTML = '';
  instance = null;
  router = null;
}
