import request from '@/utils/request'
// 文明实践接口
export function getDomain(){
  return request({
    url:`/v1/cms/api_domain`,
    method:"get"
  })
}
// 获取文明实践增值资源状态
export function getModuleByParctice(params) {
  return request({
    url: `/v1/platform/system/app/module`,
    method: "get",
    params
  });
}
// 机构↓
// 获取机构列表
export function getMerchantsTreeList(params) {
  return request({
    url: `/v1/platform/merchant/treelist`,
    method: "get",
    params
  });
}

// 获取机构下站所列表
export function getMerchantsListById(params) {
  return request({
    url: `/v1/platform/cpcstation/cheldrenlist`,
    method: "POST",
    data:params
  });
}

// 获取上级机构数据
export function getMerchartsListByType(params) {
  return request({
    url: `/v1/platform/cpcstation/parent`,
    method: "GET",
    params
  });
}

// 编辑机构
export function editMerchants(params) {
  return request({
    url: `/v1/platform/merchants/${params.id}`,
    method: "PUT",
    data:params
  });
}

// 根据id获取机构详情
export function getMerchantsInfoById(params) {
  return request({
    url: `v1/platform/merchants/${params.id}`,
    method: "GET",
    params
  });
}

// 删除机构
export function deleteMerchants(params) {
  return request({
    url: `/v1/platform/merchants/${params.id}`,
    method: "DELETE",
  });
}

// 启用/停用机构
export function changeMerchantsStatus(params) {
  return request({
    url: `/v1/platform/merchants/${params.id}/status`,
    method: "PATCH",
    params
  });
}


// 志愿者团队↓
// 获取志愿者团队列表
export function getTeamlist(params) {
  return request({
    url: "/v1/platform/cpcteams/teamlist",
    method: "get",
    params,
  });
}

// 志愿者
// 获取志愿者列表
export function getVolunteerlist(params) {
  return request({
    url: "/v1/platform/volunteer/list",
    method: "get",
    params,
  });
}

// 获取文明分记录
export function getGradeRecord(params) {
  return request({
    url: "/v1/platform/volunteer/cpcgrade",
    method: "get",
    params,
  });
}

// 志愿者审核
export function VolunteerExamine(params) {
  return request({
    url: `/v1/platform/volunteer/detail/${params.id}/status`,
    method: "PATCH",
    data:params
  });
}
// 志愿者删除
export function deleteVolunteer(params) {
  return request({
    url: `/v1/platform/volunteer/detail/${params.id}`,
    method: "DELETE",
  });
}
// 志愿者详情
export function getVolunteerDetails(params) {
  return request({
    url: `/v1/platform/volunteer/detail/${params.id}`,
    method: "GET",
  });
}
// 服务类型↓
//获取服务类型列表
export function getServiceTypeList(params) {
  return request({
    url: "/v1/platform/cpcservice/list",
    method: "get",
    params,
  });
}

//新增服务类型
export function addServiceType(params) {
  return request({
    url: `/v1/platform/cpcservice/detail`,
    method: "POST",
    params,
  });
}

//编辑服务类型
export function editServiceType(params) {
  return request({
    url: `/v1/platform/cpcservice/detail/${params.id}`,
    method: "PUT",
    params,
  });
}

//删除服务类型
export function deleteServiceType(params) {
  return request({
    url: `/v1/platform/cpcservice/detail/${params.id}`,
    method: "DELETE",
  });
}

//审核管理 ↓
//获取审核管理列表
export function getExamineList(params) {
  return request({
    url: `/v1/platform/cpcconfig/list`,
    method: "get",
  });
}

//审核管理编辑
export function editExamine(params) {
  return request({
    url: `/v1/platform/cpcconfig/detail/${params.code}`,
    method: "PUT",
    data:params,
  });
}
//获取机构列表
export function fetchTopOrgs(params) {
	return request({
		url: '/v1/platform/cpcstation/parent',
		method: 'get',
		params
	})
}
//文明中心机构树型结构查询
export function fetchMerchantTreelist(params) {
	return request({
		url: '/v1/platform/merchant/treelist',
		method: 'get',
		params
	})
}

//团队审核
export function teamExamine(params) {
  return request({
    url: `/v1/platform/cpcteams/${params.id}/status`,
    method: "PATCH",
    data:params,
  });
}
//文明中心机构批量录入
export function merchantsImport({path, data}) {
	return request({
		headers: {
				'Content-Type': 'multipart/form-data'
		},
		timeout: 1000 * 60 * 30,
		url: path,
		method: 'post',
		data
	})
}
//批量录入查询进度
export function fetchPlatformProgress(params) {
	return request({
		url: '/v1/platform/progress',
		method: 'get',
		params
	})
}
// wm2
// 获取称号列表
export function getTitleList(params) {
  return request({
    url: `/v1/platform/medals`,
    method: "GET",
    params,
  });
}

// 获取称号列表
export function addTitle(params) {
  return request({
    url: `/v1/platform/medal`,
    method: "POST",
    params,
  });
}

// 修改称号
export function editTitle(params) {
  return request({
    url: `/v1/platform/medal/${params.id}`,
    method: "PUT",
    params,
  });
}

// 删除称号
export function deleteTitle(params) {
  return request({
    url: `/v1/platform/medal/${params.id}`,
    method: "DELETE",
    params,
  });
}

// 获取配置管理信息
export function getConfigMangementInfo(params) {
  return request({
    url: `/v1/platform/help/config`,
    method: "GET",
  });
}

// 修改配置管理信息
export function editConfigMangementInfo(params) {
  return request({
    url: `/v1/platform/help/config/${params.id}`,
    method: "PUT",
    params
  });
}

// 获取帮扶对象列表
export function getHelpObjList(params) {
  return request({
    url: `/v1/platform/helpers`,
    method: "GET",
    params
  });
}

// 添加帮扶对象
export function addHelpObj(params) {
  return request({
    url: `/v1/platform/helper`,
    method: "POST",
    data:params
  });
}

// 修改帮扶对象
export function editHelpObj(params) {
  return request({
    url: `/v1/platform/helper/${params.id}`,
    method: "PUT",
    data:params
  });
}

// 删除帮扶对象
export function deleteHelpObj(params) {
  return request({  
    url: `/v1/platform/helper/${params.id}`,
    method: "DELETE",
    params
  });
}

// 获取帮扶详情
export function geteHelpObjDetails(params) {
  return request({  
    url: `/v1/platform/helpers/${params.id}`,
    method: "GET",
    params
  });
}

// 批量授予称号
export function batchGrantTitle(params) {
  return request({  
    url: `/v1/platform/award_medals`,
    method: "POST",
    params
  });
}

// 志愿者添加帮扶对象
export function volunteerAddHelpOjb(params) {
  return request({  
    url: `/v1/platform/volunteer_helper`,
    method: "POST",
    data:params
  });
}

// 获取志愿者关联的帮扶对象
export function getVoliunteerRelationHelper(params) {
  return request({  
    url: `/v1/platform/v_helpers/${params.id}`,
    method: "GET",
    params
  });
}

// 志愿者移除帮扶人
export function vDeleteHelpObj(params) {
  return request({  
    url: `/v1/platform/volunteer_move_helper`,
    method: "DELETE",
    params
  });
}

// 获取帮扶人帮扶记录列表
export function getHelperList(params) {
  return request({  
    url: `/v1/platform/v_relation_helper`,
    method: "GET",
    params
  });
}

// 帮扶人或帮扶对象日历
export function getVrelationDate(params) {
  return request({  
    url: `/v1/platform/v_relation_date`,
    method: "GET",
    params
  });
}

// 根据时间获取记录
export function getVdayRecordsByTime(params) {
  return request({  
    url: `/v1/platform/v_day_records`,
    method: "GET",
    params
  });
}

// 获取文明实践配置
export function getPracticeConfig(params){
  return request({  
    url: `/v1/platform/civilization_config`,
    method: "GET"
  });
}

// 修改文明实践配置
export function editPracticeConfig(params){
  return request({  
    url: `/v1/platform/civilization_config/${params.id}`,
    method: "PUT",
    data:params
  });
}

// 项目管理
// 项目列表
export function getProjectManagementList(params){
  console.log('params :>> ', params);
  return request({  
    url: `v1/platform/project/list`,
    method: "GET",
    params
  });
}

// 创建项目
export function creatProject(params){
  return request({  
    url: `v1/platform/project`,
    method: "POST",
    data:params
  });
}
//编辑项目
export function editProject(id,params){
  return request({  
    url: `v1/platform/project/${id}`,
    method: "PUT",
    data:params
  });
}
//排序项目
export function sortProject(id,params){
  return request({  
    url: `/v1/platform/project/${id}/sort`,
    method: "PATCH",
    data:params
  });
}