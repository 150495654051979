const state = {
  menu: {
    creditShow: false, //文明实践增值资源
    practiceShow: false, //积分商城增值资源
  },
  PairTabsShow: false,
};

const getters = {
  getCreditShow: (state) => state.menu.creditShow,
  getPracticeShow: (state) => state.menu.practiceShow,
  getPairTabsShow: (state) => state.PairTabsShow,
};

const mutations = {
  setModules(state, { k, v }) {
    state.menu[k] = v;
  },
  setPairTabsShow(state, flag) {
    console.log(flag,'==')
    state.PairTabsShow = flag;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
