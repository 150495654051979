import request from "@/utils/request";
import axios from 'axios';
let API_BASE = process.env.VUE_APP_API_BASE || '/api';

/*
* 登录
* */
export function apiLogin(parmas) {
  return request({
    url: '/v1/secure_login/account_login',
    method: 'post',
    data:parmas
  });
//   return mAxios.post(
//     '/v1/login',
//     JSON.stringify(parmas)
//   );
}

/*
* 登出
* */
export function apiLogout(params) {
  return request({
    url:'/v1/loginOut',
    method:'get',
    headers:{
      Authorization:params.Authorization
    }
  });
//   return mAxios.get(encodeURI(`/v1/loginOut`));
}

/*
* 验证手机是否存在于系统
* */
export function valiPhone(phone) {
  return request({
    url:`/v1/phone/${phone}`,
    method:'get'
  });
//   return mAxios.get(encodeURI(`/v1/phone/${phone}`));
}

/*
* 发送验证码
* */
export function sendCode(params) {
  return request({
    url:`/v1/secure_login/phone_captcha`,
    method:'get',
    params
  });
//   return mAxios.get(encodeURI(`/v1/sendCode?${query}`));
}

/*
* 通过验证码修改密码
* */
export function editPassword(parmas) {
  return request({
    url:'/v1/secure_login/update_password_by_verification',
    method:'patch',
    data:parmas
  });
//   return mAxios.put(
//     '/v1/password',
//     JSON.stringify(parmas)
//   );
}

// 获取用户信息
export function getUserInfo(parmas){
  return request({
    url:'/v1/user_info',
    method:'get',
    headers:{
      Authorization:parmas.Authorization
    }
  })
}

// 获取登录二维码
export function getLoginQrcodeInfo(query = '') {
  // return mAxios.get(encodeURI(`/v1/merchant/wechat/login_qr?${query}`));
  return axios({
    url:`${API_BASE}/v1/merchant/wechat/login_qr?${query}`,
    method: 'get',
    timeout: 10000
  })
}

// 获取登录二维码扫描结果
export function getLoginQrcodeScanInfo(id, query = '') {
  return request.get(encodeURI(`/v1/platform/wechat/login_qr/${id}?${query}`));
}

// 获取安全登录配置
export function getLoginConfigure(params) {
  return request({
    url: '/v1/secure_login/configs',
    method: 'get',
    params
  })
}

// 获取微信账号信息  appid
export function getAppId() {
  return request({
    url: '/v1/secure_login/wechat_info',
    method: 'get',
  })
}

// 微信 用户信息 --后台
export function getWxUserInfo(params) {
  return request({
    url: '/v1/wechat/pc_auth_user',
    method: 'get',
    params
  })
}

// 第三方账号登录  微信
export function wxLogin(params) {
  return request({
    url: '/v1/secure_login/proxy_login',
    method: 'post',
    params
  })
}
// 根据code获取微信用户信息
export function getWechatUserInfo(params) {
  return request({
    url: `/v1/wechat/pc_auth_user`,
    method: 'get',
    params
  })
}
/*
 * 绑定微信账号
 * */
export function phoneBindWX(params) {
  return request({
    url: `/v1/consumer/bind_wechat`,
    method: 'post',
    data: params
  })
}
// 获取第三方平台绑定
export function getProxies(params) {
  return request({
    url: '/v1/consumer/proxies',
    method: 'GET',
    params,
  })
}
// 取消第三方平台绑定
export function DelProxies(params) {
    return request({
        url: '/v1/consumer/proxies',
        method: 'DELETE',
        data:params,
    })
}

//获取RSA公钥

export function getRsa() {
  return request({
    url: `/v1/secure_login/public_key`,
    method: 'GET',
  })
}
