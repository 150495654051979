<template>
    <div class="menu-wrapper">
        <div>{{isAdmin}}</div>
        <template v-for="item in routes" v-if="!item.hidden&&item.children">
            <router-link v-if="hasOneShowingChildren(item.children) &&!item.children[0].children&&!item.alwaysShow" :to="item.path+'/'+item.children[0].path"
                :key="item.children[0].name">
                <el-menu-item :index="item.children[0].meta.menuId" :class="{'submenu-title-noDropdown':!isNest}">
                    <i v-if="item.children[0].meta&&item.children[0].meta.icon" class="icon" :class="item.children[0].meta.icon"></i>
                    <span v-if="item.children[0].meta&&item.children[0].meta.title" slot="title">{{item.children[0].meta.title}}</span>
                </el-menu-item>
            </router-link>

            <el-submenu v-else-if="item.meta.title!='系统设置'||item.meta.title=='系统设置'&&isAdmin==1" :index="item.meta.menuId" :key="item.name" >
                <template slot="title">
                    <i v-if="item.meta&&item.meta.icon" class="icon" :class="item.meta.icon"></i>
                    <span v-if="item.meta&&item.meta.title" slot="title">{{item.meta.title}}</span>
                </template>
                <template v-for="child in item.children" v-if="!child.hidden">
                    <sidebar-item :is-nest="true" class="nest-menu" v-if="child.children&&child.children.length>0" :routes="[child]" ></sidebar-item>
                    <router-link v-else :to="item.path+'/'+child.path" :key="child.name" >
                        <el-menu-item :index="child.meta.menuId">
                            <i v-if="child.meta&&child.meta.icon" class="icon" :class="child.meta.icon"></i>
                            <span v-if="child.meta&&child.meta.title" slot="title">{{child.meta.title}}</span>
                        </el-menu-item>
                    </router-link>
                </template>
            </el-submenu>
        </template>
    </div>
</template>

<script>
export default {
  name: 'SidebarItem',
  props: {
    routes: {
      type: Array
    },
    isNest: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Number,
      default: 0
    }
  },

  methods: {
    // 注释
    hasOneShowingChildren(children) {
      const showingChildren = children.filter(item => {
        return !item.hidden
      });
      if (showingChildren.length === 1) {
        return true
      }
      return false
    }
  }
}
</script>

