import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import permission from './modules/permission'
import practice from './modules/practice'
import user from './modules/user'
import base from './modules/base';
import systemData from './modules/systemData';

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    permission,
    practice,
    user,
    base,
    systemData
  }
})

export default store
