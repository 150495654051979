import request from '@/utils/request'
/*
   新建订单获取获取机构列表  ---危杰
*/
export function getOrganization(url) {
    return request({
        url: `${url}/v1/merchants`,
        method: 'get',
    })
}
/*
   获取平台分组  ---危杰
*/
export function getGroups(url) {
    return request({
        url: `${url}/v1/platform_groups`,
        method: 'get',
    })
}
/*
   获取平台分组成员  ---危杰
*/
export function getMembers(url, params) {
    return request({
        url: `${url}/v1/platform_groups/${params.id}/members`,
        method: 'get',
        params
    })
}

/*
   新建订单  ---危杰
*/
export function newOrders(url, params) {
    return request({
        url: `${url}/v1/orders`,
        method: 'POST',
        data: params,
    })
}


/*
   获取订单详情  ---危杰
*/
export function getOrdersDeatil(url, params) {
    return request({
        url: `${url}/v1/orders/` + params.id,
        method: 'get',
    })
}

/*
   获取机构订单列表  ---危杰
*/
export function getOrderList(url, params) {
    return request({
        url: `${url}/v1/orders`,
        method: 'get',
        params
    })
}
/*
   获取机构资源用量列表  ---危杰
*/
export function getResourceList(url, params) {
    return request({
        url: `${url}/v1/merchant_resources`,
        method: 'get',
        params
    })
}
/*
   获取机构资源用量详情  ---危杰
*/
export function getResourceDeatil(url, params) {
    return request({
        url: `${url}/v1/p_merchants/${params.id}/resource_details`,
        method: 'get',
    })
}

/*
   获取续费日志列表  ---危杰
*/
export function getRenewLog(url, params) {
    return request({
        url: `${url}/v1/p_merchants/${params.id}/renew_logs`,
        method: 'get',
        params
    })
}

/*
   续费  ---危杰
*/
export function newRenew(url, params) {
    return request({
        url: `${url}/v1/p_merchants/${params.id}/resources`,
        method: 'PATCH',
        data: params
    })
}
/*
   P 端获取计费中心基础信息  ---危杰
*/
export function baseInfo() {
    return request({
        url: '/v1/platform/emc_basic_info',
        method: 'GET',
    })
}



/*
 * 支付____________________________________
 * */
// 总览
export function getPayOverview(url, params) {
    return request({
        url: `${url}/v1/platform/account/${params.merchant_id}`,
        method: 'get'
    })
}

// 账单列表
export function getPayList(url, params) {
    return request({
        url: `${url}/v1/platform/account/details/${params.merchant_id}`,
        method: 'get',
        params
    })
}

// 机构列表
export function getMerchants(url, params) {
    return request({
        url: `${url}/v1/merchants`,
        method: 'get',
        params
    })
}
// 提现设置
export function newCash(url, params) {
    return request({
        url: `${url}/v1/platform/transfers/config`,
        method: 'POST',
        data: params,
    })
}
// 提现列表
export function getCashList(url, params) {
    return request({
        url: `${url}/v1/platform/transfers`,
        method: 'get',
        params
    })
}
// 提现拒绝
export function getCashrefuse(url, params, id) {
    return request({
        url: `${url}/v1/platform/transfers/${id}/refuse`,
        method: 'put',
        params
    })
}

// 提现统计
export function getCashstatistics(url) {
    return request({
        url: `${url}/v1/platform/transfer_statistics`,
        method: 'get',
    })
}

// 获取提现规则设置
export function getRule(url) {
    return request({
        url: `${url}/v1/platform/transfers/config`,
        method: 'get',
    })
}
// 获取提现详情
export function getTransid(url, id) {
    return request({
        url: `${url}/v1/platform/transfers/${id}`,
        method: 'get',
    })
}
// P 端禁用用户提现
export function joinBlackList(url, params) {
    return request({
        url: url + `/v1/platform/forbidden_transfer`,
        method: 'post',
        data: params
    })
}
// P 端解禁用户提现
export function removeBlackList(url, params) {
    return request({
        url: url + `/v1/platform/allow_transfer`,
        method: 'post',
        data: params
    })
}
//获取禁用用户名单
export function getBlackList(url, params) {
    return request({
        url: url + `/v1/platform/forbidden_transfer_users`,
        method: 'get',
        params
    })
}


/*获取P端用户列表*/
export function getPUserList(url, params) {
    return request({
        url: url + `/v1/platform/staffs`,
        method: 'GET',
        params
    })
}
// 获取可提现金额列表
export function getWithdrawableList(url, params) {
    return request({
        url: url + `/v1/platform/withdrawable_amounts`,
        method: 'get',
        params
    })
}

// 获取支付宝余额
export function getAlipayBalance(url, params) {
    return request({
        url: url + `/v1/consumer/platform_alipay`,
        method: 'get',
        params
    })
}


// 获取通用资源情况 --- 黄庚
export function getPublicResour(params) {
    return request({
        url: `/v1/platform/resources`,
        method: 'get',
        params
    })
}

// 获取应用资源情况 --- 黄庚 
export function getPlatformAppResour(params) {
    return request({
        url: `/v1/platform/app/modules`,
        method: 'get',
        params
    })
}


// 获取应用资源情况 --- 黄庚 (没用到了)
export function getAppResour(params) {
    return request({
        url: `/v1/platform/modules`,
        method: 'get',
        params
    })
}

// 获取应用资源使用详情 --- 黄庚
export function getModulesDetail(params) {
    return request({
        url: `/v1/platform/module_details`,
        method: 'get',
        params
    })
}
// 微信提现-下载文件
export function getWXListFile(url, params) {
    return request({
        url: `${url}/v1/platform/transfer_wechat_export`,
        method: 'POST',
        data: params,
    })
}
// 微信提现-置为提现成功
export function setCashSuccess(url, params) {
    return request({
        url: `${url}/v1/platform/transfer_wechat`,
        method: 'POST',
        data: params,
    })
}

// 获取账号绑定信息
export function getBind(url, params) {
    return request({
        url: `${url}/v1/platform/pay_accounts`,
        method: 'get',
        params
    })
}

// 设置账号绑定信息
export function getAliUrl(url, params) {
    return request({
        url: `${url}/v1/platform/alipay_url`,
        method: 'get',
        params
    })
}