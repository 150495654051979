<template>
  <transition name="fade-transform" mode="out-in">
    <router-view :key="key" v-if="isInnerLink">
      <div slot="source">
        <breadcrumb></breadcrumb>
      </div>
    </router-view>
  </transition>
</template>

<script>
import Breadcrumb from "@/views/layout/components/breadcrumb";
export default {
  name: "AppMain",
  components: {
    Breadcrumb
  },
  computed: {
    key() {
      // console.log(this.$route.fullPath)
      return this.$route.fullPath;
    },
    isInnerLink() {
      return !(this.key == '/subSystem/334' || this.key == '/subSystem/337')
    },
  }
};
</script>
<style lang="scss" scoped>
@import "../../../assets/sass/helpers/_mixin.scss";
@import "../../../assets/sass//helpers/_variables";
.app-main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: auto;
  background: $content-Bg;
}
</style>

