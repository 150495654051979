/**
 *
 * 查找数组，返回匹配到的第一个index
 * @author Lynn
 * @param array 被查找的数组
 * @param feature 查找特征 或者为一个具体值，用于匹配数组遍历的值，或者为一个对象，表明所有希望被匹配的key-value
 * @param or boolean 希望命中feature全部特征或者只需命中一个特征，默认true
 *
 * @return 数组下标  查找不到返回-1
 */
export function findArray(array, feature) {
  var all =
    arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  for (var index in array) {
    var cur = array[index];
    if (feature instanceof Object) {
      var allRight = true;
      for (var key in feature) {
        var value = feature[key];
        if (cur[key] == value && !all) return index;
        if (all && cur[key] != value) {
          allRight = false;
          break;
        }
      }
      if (allRight) return index;
    } else {
      if (cur == feature) {
        return index;
      }
    }
  }
  return -1;
}
// 转换金额
export const transMoney = {
  // 元转分
  positive: function(val, times = 100) {
    try {
      val = parseFloat(val);
    } catch (e) {
      console.log(e);
      return 0;
    }
    if (!val) {
      return 0;
    }
    return val * times;
  },
  // 分转元
  negative: function(val, times = 100) {
    try {
      val = parseFloat(val);
    } catch (e) {
      console.log(e);
      return 0;
    }
    if (!val) {
      return 0;
    }
    return (val / times).toFixed(2);
  },
};
// 生成32位uuid
export function uuidGenerator() {
  var originStr = "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
    originChar = "0123456789abcdef",
    len = originChar.length;
  return originStr.replace(/x/g, function(match) {
    return originChar.charAt(Math.floor(Math.random() * len));
  });
}
/**
 * 输入框限制输入 只允许输入数字
 * value 数值 必填
 * max 最大值 选填
 * min 最小值 选填
 * length 小数点保留位数 选填
 * isBlur 失去焦点触发
 */
 export function limitInputNumber(value, max = null, min = null, length = null, isBlur = false, isMoney = false) {
  if (!value && value !== 0) {
    return min >= 0 ? min : null;
  }
  //是否需要保留小数
  value = value.toString();
  if (!length) {
    if (max < 0 || min < 0) {
      value = value.replace(/[^\-\d]+/g, '')
    } else {
      value = value.replace(/[^0-9]/ig, "")
    }
  } else {
    // 保留数字和小数点
    if (max < 0 || min < 0) {
      value = value.replace(/[^\-\d^\.]+/g, '')
    } else {
      value = value.replace(/[^\d^\.]+/g, '')
    }
    if (value.indexOf('.') > 0) {
      value = value.slice(0, value.indexOf('.') + (length + 1));
    } else if (value.indexOf('.') === 0 && value.length === 1) {
      value = 0 + value;
    } else if (value.indexOf('.') === value.length - 1 && value.length != 0 && isBlur) {
      value = value.substring(0, value.length - 1);
    }
  }
  if (max) {
    max = Number(max);
    let tempValue = Number(value);
    if (tempValue > max) {
      value = max;
    }
  }
  if (min) {
    min = Number(min);
    let tempValue = Number(value);
    if (tempValue < min) {
      value = min;
    }
  }
  if (isMoney) {
    for (var i = 0; i < value.length; i++) {
      if (value[i] === '.') {
        break
      }
      if (value[i] == 0 && value[i + 1] !== '.' && i == 0) {
        value = value.slice(i + 1, value.length - 1)
        i--
      }
    }
  }
  if (isBlur) {
    return Number(value);
  } else {
    return value;
  }
}

// 节流
let timer, flag;
/**
 * 节流原理：在一定时间内，只能触发一次
 *
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
export function throttle (func, wait = 500, immediate = true) {
	if (immediate) {
		if (!flag) {
			flag = true;
			// 如果是立即执行，则在wait毫秒内开始时执行
			typeof func === 'function' && func();
			timer = setTimeout(() => {
				flag = false;
			}, wait);
		}
	} else {   
    if(timer){
      flag = false
      clearTimeout(timer);
    } 
		if (!flag) {      
			flag = true      
			// 如果是非立即执行，则在wait毫秒内的结束处执行
			timer = setTimeout(() => {
				flag = false
				typeof func === 'function' && func();
			}, wait);
		}
	}
}

// 即将到期判断
export function isDateDue(date) {
  let current_time = new Date().getTime()
  let expired_at = new Date(date).getTime()
  let interval = expired_at - current_time
  if(interval >= 0 && interval <= 2592000000) {
    return true
  } else {
    return false
  }
}
export function formatNumber(num) {
  if (typeof num !== 'number') {
    return 0;
  }
  return num.toFixed(2);
}

export function clone(Obj) {
  var buf;
  //指针
  if (Obj instanceof Array) {
      buf = []; //创建一个空的数组 
      var i = Obj.length;
      while (i--) {
          buf[i] = clone(Obj[i]);//防止属性是数组或对象
      }
      return buf;
  } else if (Obj instanceof Object) {
      buf = {}; //创建一个空对象 
      for (var k in Obj) { //为这个对象添加新的属性 
          buf[k] = clone(Obj[k]);
      }
      return buf;
  } else { //普通变量直接赋值
      return Obj;
  }
}