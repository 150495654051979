// 引入 actions 实例
import actions from "@/actions";

export default {

    mounted() {
        // 注册观察者函数：onGlobalStateChange 第二个参数为 true，表示立即执行一次观察者函数
        actions.onGlobalStateChange((state,prev) => {
            this.handleActionChange(state, prev);
        }, true);
    },

};