import request from '@/utils/request'
import qs from 'qs'
/**
 * 区分axios get和post区别
 * @param params 是添加到url的请求字符串中的，用于get请求
 * @param data 是添加到请求体（body）中的， 用于post请求
 * @method  qs 使用qs库是post数据传递伟form data形式，不是的话会是Request Payload形式。jq ajax Post提交就是form data形式
 */
//子网管理配置列表及子网管理列表
export function getNetwork(params) {
    return request({
        // url: '/subsystem/search',
        url:'/v1/platform/apps',
        method: 'get',
        params
    })
}
//删除接口
export function delData(params){
    return request({
        // url: '/subsystem/delete',
        // method: 'post',
        url: '/v1/platform/apps/'+params.id,
        method: 'delete',
        header:{
            'Content-Type':'application/json'
        },
        // data:params
    })
}

//获取单个信息
export function getSigleInfo(params) {
    return request({
        // url: '/subsystem/info',
        url: '/v1/platform/apps/'+params.id,
        method: 'get',
        // params
    })
}

//创建子网系统接口
export function setNetwork(params) {
    return request({
        url: '/v1/platform/apps',
        method: 'post',
        header:{
            'Content-Type':'application/json'
        },
        data:params
    })
}

//更新子网系统接口
export function updateNetwork(params) {
    return request({
        // url: '/subsystem/update',
        // method: 'post',
        url: '/v1/platform/apps/'+params.id,
        method: 'put',
        data:params
    })
}

//状态切换
export function handleStatus(params) {
    return request({
        url: '/subsystem/stop',
        method: 'post',
        params
    })
}

//重新生成密钥接口
export function proSecret(params) {
    return request({
        // url: '/subsystem/get_secret',
        // method: 'get',
        url: '/v1/platform/apps/'+params.id+'/secret',
        method: 'patch',
        header:{
            'Content-Type':'application/x-www-form-urlencoded'
        },
        // params
    })
}