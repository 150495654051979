// import mAxios from "../../utils/axios";
import request from '@/utils/request'
import {cookie} from '@/utils/cookie';
// import {cookier} from "../../utils/index";


export function getLogin() {
    return request({
        url: '/user/login_user',
        method: 'get'
    })
}

//获取用户权限
export function getPermissions() {
    return request({
        url: '/user/permissions',
        method: 'get'
    })
}
// /*
// * 登录
// * */
// export function apiLogin(params) {
//   return mAxios.post(
//     '/v1/login',
//     JSON.stringify(params)
//   );
// }

// /*
// * 登出
// * */
// export function apiLogout() {
//   return mAxios.get(encodeURI(`/v1/loginOut`));
// }

// /*
// * 验证手机是否存在于系统
// * */
// export function valiPhone(phone) {
//   return mAxios.get(encodeURI(`/v1/phone/${phone}`));
// }

// /*
// * 发送验证码
// * */
// export function sendCode(query = '') {
//   return mAxios.get(encodeURI(`/v1/sendCode?${query}`));
// }

// /*
// * 修改密码
// * */
// export function editPassword(params) {
//   return mAxios.put(
//     '/v1/password',
//     JSON.stringify(params)
//   );
// }

// /*
// * 用户信息(sso登录用)
// * */
// export function getUserInfo() {
//   mAxios.defaults.headers.common['Authorization'] = cookier.get('Authorization');
//   return mAxios.get(encodeURI(`/v1/user_info`));
// }

// /*
// * 子系统列表
// * */
// export function getUserApps() {
//   // mAxios.defaults.headers.common['Authorization'] = cookier.get('Authorization');
//   return mAxios.get(encodeURI(`/v1/merchant/user/apps`));
// }

// /*
// * 个人信息（用户中心用）
// * */
// export function getPersonalInfo() {
//   return mAxios.get(encodeURI(`/v1/merchant/user`));
// }

// /*
// * 修改个人信息（用户中心用）
// * */
// export function editPersonalInfo(params) {
//   return mAxios.put(
//     '/v1/merchant/user',
//     JSON.stringify(params)
//   );
// }

// /*
// * 获取验证码
// * */
// export function getCode(query = '') {
//   return mAxios.get(encodeURI(`/v1/merchant/phone_captcha?${query}`));
// }

// /*
// * 验证验证码
// * */
// export function valiCode(params) {
//   return mAxios.put(
//     '/v1/merchant/phone_captcha',
//     JSON.stringify(params)
//   );
// }

// /*
// * 修改手机号
// * */
// export function editUserPhone(params) {
//   return mAxios.put(
//     '/v1/merchant/user/phone',
//     JSON.stringify(params)
//   );
// }


// 个人信息（用户中心用）
export function getPersonalInfo(){
    return request({
        url:'/v1/platform/user',
        method:'get'
    })
}
// 修改个人信息（用户中心用）
export function editPersonalInfo(params){
    console.log(params);
    return request({
        url:'/v1/platform/user',
        method:'put',
        headers:{
            'Content-Type':'application/json'
        },
        data:params
    })
}
// 获取验证码
export function getCode(params){
    return request({
        url:'/v1/platform/phone_captcha',
        method:'get',
        headers:{
            'Content-Type':'application/x-www-form-urlencoded'
        },
        params
    })
}
// 验证验证码 旧/v1/merchant/phone_captcha 新  /v1/secure_login/phone_captcha
export function valiCode(params){
    return request({
        url: '/v1/secure_login/phone_captcha',
        method: 'put',
        data: params
    })
}
// 修改手机号 旧 /v1/platform/user/phone 新 /v1/secure_login/phone_captcha
export function editUserPhone(params){
    return request({
        url: '/v1/secure_login/phone',
        method: 'PATCH',
        data: params
    })
}
// 修改手机号
export function editLoginName(params){
    return request({
        url:'/v1/platform/user/login_name',
        method:'put',
        data:params
    })
}
// 发送验证码
export function sendCode(params){
    return request({
        url:'/v1/sendCode',
        method:'get',
        headers:{
            'Content-Type':'application/x-www-form-urlencoded'
        },
        params
    })
}
// 修改手机号
export function editUserInfo(params){
    return request({
        url:'/v1/platform/user/'+params.id,
        method:'put',
        data:params
    })
}
// 修改密码
export function editPassword(params){
    return request({
        url:'/v1/password',
        method:'put',
        data:params
    })
}

// 微信登录相关
// 获取绑定二维码  /v1/merchant/wechat/bind_qr
export function getQrcodeInfo(query = '') {
  return request.get(encodeURI(`/v1/platform/wechat/bind_qr?${query}`));
}

// 获取绑定二维码扫描结果  /v1/merchant/wechat/bind_qr
export function getQrcodeScanInfo(id, query = '') {
  return request.get(encodeURI(`/v1/platform/wechat/bind_qr/${id}?${query}`));
}

// 获取第三方绑定情况
export function getBindInfo(query = '') {
  request.defaults.headers.common['Authorization'] = cookie.get('Authorization');
  return request.get(encodeURI(`/v1/platform/user/proxies?${query}`));
}

//  2022-10-7 新增功能 P端解锁用户
export function unlockUser(params){
    return request({
        url:'/v1/platform/secure_login/unlock_user',
        method:'PATCH',
        data:params
    })
}
// 2022-10-7 新增功能  P端重置密码
export function resetPasswords(params){
    return request({
        url:'/v1/platform/secure_login/reset_password',
        method:'PATCH',
        data:params
    })
}
// 2022-10-7 新增接口替换原有获取手机验证码 获取手机验证码
export function getPhoneCaptcha(params){
    return request({
        url:'/v1/secure_login/phone_captcha',
        method:'GET',
        params
    })
}
// 2022-10-7 新增接口替换原有通过验证码修改密码 通过验证码修改密码
export function UpdatePasswordVerification(params){
    return request({
        url:'/v1/secure_login/update_password_by_verification',
        method:'PATCH',
        data:params
    })
}
// 2022-10-7 新增接口  账号密码方式修改密码
export function AccountModifyPassword(params){
    return request({
        url:'/v1/secure_login/update_password',
        method:'PATCH',
        data:params
    })
}
// 2022-10-7 新增接口替换原有修改登录名（因原有修改用户名只支持验证码修改不支持账号密码修改）修改登录名
export function UpdateLoginName(params){
    return request({
        url:'/v1/secure_login/update_login_name',
        method:'PATCH',
        data:params
    })
}
