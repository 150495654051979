<template>
  <div class="app-header clearfix" :class="{ collapse }">
    <!-- <div class="nav-logo "></div> -->
    <i
      class="map-ctrl iconfontmenu"
      :class="collapse ? 'iconshouqi-copy' : 'iconshouqi'"
      @click="toggleClick()"
      style="margin: 0 20px 0 20px"
    ></i>
    <span class="plat-name">管理平台</span>
    <div class="avatar-container">
      <el-button
        type="primary"
        round
        size="small"
        class="new-btn"
        @click="toNewVersion"
        v-if="showNewVer"
        >
        <img src="../../../assets/image/vector.png" alt>
        体验新版</el-button
      >
      <!-- 消息中心 -->
      <div class="messageBox">
        <div class="message-tooip">
          <el-popover
            class="notice-popover"
            placement="bottom"
            width="340"
            @show="toggleVisble"
            trigger="hover"
          >
            <div class="user-set">
              <ul class="message-table">
                <li
                  :class="{ 'message-active': messageNav == '1' }"
                  @click="messageToggle(1)"
                >
                  系统
                </li>
                <li
                  :class="{ 'message-active': messageNav == '3' }"
                  @click="messageToggle(3)"
                >
                  待办
                </li>
              </ul>
              <p class="line"></p>
              <template v-if="messageNav == '1'">
                <div class="system-message">
                  <div class="system-list">
                    <ul class="message-item">
                      <li
                        v-for="item in list1"
                        :key="item.id"
                        @click.stop="handleNoticeClick(item)"
                        style="cursor: pointer"
                      >
                        <i class="message-pic"></i>
                        <div class="message-title">
                          <div>{{ item.message.content.title }}</div>
                          <div class="message-info">
                            {{ item.message.content.description }}
                          </div>
                          <div class="message-create">
                            {{ item.created_at }}
                          </div>
                        </div>
                        <p class="line"></p>
                      </li>
                      <p class="null" v-if="!list1.length">暂无未读消息</p>
                    </ul>
                    <ul class="message-toogle">
                      <li @click.stop="clearUnread">清空通知</li>
                      <li>
                        <router-link to="/message">查看更多</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
              <template v-if="messageNav == '3'">
                <div class="system-message">
                  <div class="system-list">
                    <ul class="message-item">
                      <li
                        v-for="item in list3"
                        :key="item.id"
                        @click.stop="handleNoticeClick(item)"
                        style="cursor: pointer"
                      >
                        <i class="message-pic"></i>
                        <div class="message-title">
                          <div>{{ item.message.content.title }}</div>
                          <div class="message-info">
                            {{ item.message.content.description }}
                          </div>
                          <div class="message-create">
                            {{ item.created_at }}
                          </div>
                        </div>
                        <p class="line"></p>
                      </li>
                      <p class="null" v-if="!list3.length">暂无未读消息</p>
                    </ul>
                    <ul class="message-toogle">
                      <li @click.stop="clearUnread">清空待办</li>
                      <li>
                        <router-link to="/message-center">查看更多</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
            </div>
            <span
              class="user-name-box"
              slot="reference"
              style="cursor: pointer"
            >
              <el-badge :value="notReadCount" :max="99" class="item">
                <span
                  class="iconfontmenu iconxiaoxizhongxin"
                  style="color: #333; font-size: 25px"
                ></span>
              </el-badge>
            </span>
          </el-popover>
        </div>
      </div>

      <!-- //系统设置 -->
      <div class="systemBox">
        <el-popover
          placement="bottom"
          width="120"
          trigger="hover"
          class="seting-center"
        >
          <span
            class="iconfontmenu iconshezhi"
            style="color: #333; font-size: 25px"
            slot="reference"
          ></span>
          <div class="seting-content">
            <router-link to="/sysSetting/manager" class="link"
              >组织架构</router-link
            >
            <router-link to="/sysSetting/general-settings" class="link"
              >系统设置</router-link
            >
          </div>
        </el-popover>
      </div>
      <!-- //授权信息 -->
      <div class="llicenseBox">
        <div class="license-info">
          <el-tooltip
            class="item"
            effect="dark"
            content="授权信息"
            placement="bottom"
          >
            <span
              style="font-size: 28px; color: #1890ff"
              class="iconfont iconshouquanxinxi"
              @click="handleLicenseDiag()"
            ></span>
          </el-tooltip>
        </div>
      </div>

      <!-- 登陆信息 -->
      <!-- <span v-text="userInfo.username"></span> -->
      <el-popover placement="bottom" width="120" trigger="hover">
        <div class="user-set">
          <router-link to="/userCenter" class="link" @click.stop="hideBoard"
            >个人中心
          </router-link>
          <router-link to="/syssetting/login-record" class="link"
            >登录日志
          </router-link>
          <a class="link logout" href="javascript:" @click.stop="logout()"
            >退出登录
          </a>
        </div>
        <div class="userBox" slot="reference">
          <div class="userlogo">
            <template v-if="logo">
              <img :src="logo" @error="avatarError" alt />
            </template>
            <template v-else>
              <img src="../../../assets/image/pic_touxiang.png" alt />
            </template>
          </div>
          <span class="user-name-box" style="cursor: pointer">
            <span class="user-staff-name">{{
              userInfo ? userInfo.staff_name : "未知名称"
            }}</span>
            <!-- <i class="arr-down"></i> -->
          </span>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { apiLogout } from "@/api/login";
// import Cookies from 'js-cookie';
import { cookie } from "@/utils/cookie";
import { getunreadNum, getNoticeList, clearUnread } from "../../../api/common";
import actions from "@/actions"; //导入实例

export default {
  props: {
    userInfo: {
      type: Object,
      default: {},
    },
    collapse: {
      type: Boolean,
      default: true,
    },
    isCollapse: false,
  },
  data() {
    return {
      //   logo:''
      messageNav: "1",
      count1: 0,
      list1: [],
      list3: [],
      count3: 0,
      notReadCount: 0,
    };
  },
  computed: {
    logo(newVal) {
      if (this.userInfo.user) {
        return this.userInfo.user.avatar;
      }
    },
    // 显示入口：测试环境或者习水环境。。
    showNewVer() {
      return (
        window.location.href.indexOf("review") > -1 ||
        window.location.href.indexOf("staging") > -1 ||
        window.location.href.indexOf("on-radio") > -1 ||
        window.location.href.indexOf("hunanradio") > -1 ||
        window.location.href.indexOf("xishuirm") > -1
      );
    },
  },
  created() {
    if (
      !window.__POWERED_BY_QIANKUN__ &&
      cookie.get("IsNewVersion") === "true"
    ) {
      this.toNewVersion();
    }
    this.fetchReadNum();
  },
  mounted() {
    //   this.logo=this.userInfo.avatar;
  },
  methods: {
    // 体验新版
    toNewVersion() {
      const protocol = window.location.protocol + "//";
      const base = "console";
      const domain = window.location.hostname.slice(
        window.location.hostname.indexOf(".")
      );
      // console.log( protocol + base + domain)
      cookie.set("IsNewVersion", true);
      cookie.set(
        "OldVersionUrl",
        window.location.protocol + "//" + window.location.hostname
      );
      window.location.href = protocol + base + domain;
    },
    handleLicenseDiag() {
      this.$emit("handleLicenseDiag");
    },
    avatarError() {
      this.logo = "";
    },
    hideBoard() {
      document.documentElement.click();
    },
    toggleClick() {
      this.$emit("update:collapse", !this.collapse);
    },
    toggleSideBar() {
      this.$store.dispatch("toggleSideBar");
    },
    logout() {
      apiLogout({ Authorization: cookie.get("Authorization") }).then((res) => {
        console.log(res);
        this.$message({
          message: res.msg,
          type: "success",
          duration: 5 * 1000,
        });
        if (window.__POWERED_BY_QIANKUN__) {
          actions.setGlobalState({ responseCode: 303001 });
          return;
        }
        localStorage.setItem("needLogout", true);
        let str = window.location.hostname.substring(
          window.location.hostname.indexOf(".")
        );
        var regIp=/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;            
        if(regIp.test(window.location.hostname)){
          str=window.location.hostname;
        }
        const ENV_TOP_DOMAIN = process.env.VUE_APP_TOP_DOMAIN;
        cookie.del("Authorization");
        this.$router.push({ path: "/login" });
        /*setTimeout(() => {
            window.location.reload();
          }, 50)*/
      });
    },
    // 动态添加删除类
    messageToggle(vlaue) {
      this.messageNav = vlaue;
      // this.messageNav = vlaue
      this.fetchNotice();
    },
    // 点击
    handleNoticeClick(item) {
      if (!item.message.content.jump_url) {
        return;
      }
      window.open(item.message.content.jump_url);
    },
    // 总数
    fetchReadNum() {
      getunreadNum({ message_type_ids: "1,2,3", client: 1 })
        .then((res) => {
          if (res.code == "100000") {
            this.notReadCount = res.data.not_read_num;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleVisble(e) {
      console.log(e);
      this.fetchNotice();
      // if (!this.visible) {
      //   this.fetchNotice()
      // }
    },
    // 获取消息
    fetchNotice(key = null) {
      getNoticeList({
        client: 1,
        page: 1,
        page_size: 4,
        message_type_ids: key || (this.messageNav == 1 ? "1,2" : 3),
      })
        .then((res) => {
          console.log(res);
          if (res.code == "100000") {
            this["count" + (key || this.messageNav)] = res.data.total;
            this["list" + (key || this.messageNav)] = res.data.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 已读
    clearUnread() {
      let ids = [];
      for (let item of this["list" + this.messageNav]) {
        ids.push(item.id);
      }
      console.log(ids);
      if (!ids.length) {
        return;
      }
      clearUnread({ message_id_arr: ids, client: 2 }).then((res) => {
        console.log(res);
        if (res.code == "100000") {
          this.fetchReadNum();
          // this.$message.success('')
          this.fetchNotice();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../../assets/sass/helpers/_mixin";
@import "../../../assets//sass/helpers/_variables";

.app-header {
  height: 64px;
  color: #fff;
  border-bottom: 1px #e9f0ff solid;
  // background: $header-Bg;
  z-index: 77;
  box-sizing: border-box;
  line-height: 50px;
  position: absolute;
  top: 0;
  left: 256px;
  right: 0;
  padding: 0 12px 0 0;

  &.collapse {
    left: 64px;
  }

  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }

  .map-ctrl {
    width: 63px;
    height: 63px;
    line-height: 63px;
    text-align: center;
    font-size: 24px;
    color: #666;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  //设置
  .systemBox {
    padding: 0 10px;
    height: 63px;
    .seting-center {
      position: relative;
      top: 4px;
    }
  }

  .systemBox:hover {
    background-color: #f9f9f9;
  }

  .plat-name {
    display: inline-block;
    height: 63px;
    line-height: 63px;
    vertical-align: bottom;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    cursor: pointer;
  }

  .nav-logo {
    float: left;
    @include wh(252px, 52px);
    margin-top: 11px;
    margin-left: 34px;
    background: url("../../../assets/image/logo.png") no-repeat center;
  }

  .avatar-container {
    display: flex;
    align-items: center;
    float: right;
    position: relative;
    height: 63px;
    line-height: 63px;
    margin-right: 60px;
    box-sizing: border-box;
    cursor: pointer;

    .new-btn {
      margin-right: 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 36px;
      background: #edf7ff;
      color: #419cf9;
      font-size: 16px;
      font-weight: bold;
      border: none;

      img {
        width: 26px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
    .userBox {
      padding: 22px 8px 0 8px;
      height: 63px;
      // padding-top: 22px;
      min-width: 96px;
      .userlogo {
        float: left;
        margin-right: 10px;

        // @include bg-logo-one( 50px,50px,"../../../assets/image/icon/ic_touxiang.png" );
        img {
          display: block;
          vertical-align: top;
          @include wh(26px, 26px);
          border-radius: 50%;
        }
      }
    }
    .userBox:hover {
      background-color: #f9f9f9;
    }

    .user-name-box {
      display: flex;
      align-items: center;

      .user-staff-name {
        display: block;
        max-width: 240px;
        // min-width: 120px;
        font-size: 14px;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-top: -18px;
      }

      .arr-down {
        @include bg-logo-one(
          15px,
          7px,
          "../../../assets/image/arr-down-header.png"
        );
        margin-left: 6px;
        color: #333;
      }
    }

    &:hover .logo-tip {
      display: block;
    }

    .logo-tip {
      position: absolute;
      display: none;
      z-index: 333;
      top: 50px;
      right: 0;
      width: 164px;
      height: 94px;
      padding-top: 10px;
      background: url("../../../assets/image/icon/navbar/bg_tanchu.png")
        no-repeat center;
      background-size: cover;

      li {
        color: #a9a9a9;

        &:hover {
          color: #6995ff;

          .icon-out {
            background-image: url("../../../assets/image/icon/navbar/ic_tuichu_hover.png");
          }

          .icon-user-center {
            background-image: url("../../../assets/image/icon/navbar/ic_gerenxinxi_hover.png");
          }
        }
      }

      .item {
        height: 30px;
        padding: 0 38px;
        cursor: pointer;

        i {
          vertical-align: -5px;
          margin-right: 2px;
        }
      }
    }

    /*dqh -- 授权信息*/
    .llicenseBox {
      height: 63px;
      // padding-left: 20px;
      padding: 15px 10px 0 10px;
      .license-info {
        position: relative;
        top: 6px;
        line-height: 30px;
        // margin-right: 20px;
      }
    }
    .llicenseBox:hover {
      background-color: #f9f9f9;
    }

    /*xm -- 消息提示*/
    .messageBox {
      height: 63px;
      line-height: 63px;
      padding: 15px 10px;
      .message-tooip {
        position: relative;
        top: 6px;
        line-height: 30px;
        margin-right: 25px;
      }
    }
    .messageBox:hover {
      background-color: #f9f9f9;
    }
  }
}

.seting-content {
  text-align: center;
  .link {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #666;
    transition: all 0.2s;
    .icon {
      vertical-align: middle;
      margin-right: 10px;
    }

    &:hover {
      color: #409eff;
    }
  }
}

.user-set {
  .link {
    display: block;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #666;
    transition: all 0.2s;

    .icon {
      vertical-align: middle;
    }

    &:hover {
      color: #409eff;

      .icon-out {
        background-image: url("../../../assets/image/icon/navbar/ic_tuichu_hover.png");
      }

      .icon-user-center {
        background-image: url("../../../assets/image/icon/navbar/ic_gerenxinxi_hover.png");
      }
    }
  }

  .message-table {
    border-bottom: 1px solid #cccccc;

    li {
      display: inline-block;
      width: 80px;
      padding-bottom: 8px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      letter-spacing: 1px;
      margin-left: 50px;
    }

    .message-active {
      color: #158cff;
      border-bottom: 2px solid #158cff;
    }
  }

  .system-message {
    .system-list {
      .message-item {
        li {
          display: flex;
          border-bottom: 1px solid #cccccc;
          padding: 15px 22px 0px 15px;

          .message-pic {
            display: inline-block;
            width: 30px;
            height: 30px;
            background: url("../../../assets/image/account/system-message.png")
              no-repeat;
            background-size: 20px;
            vertical-align: middle;
          }

          .message-title {
            width: 100%;
            font-size: 14px;
            font-weight: bold;
            margin-left: 8px;
            margin-top: -6px;
            position: relative;

            .message-info {
              font-weight: normal;
              margin-top: 10px;
              font-size: 14px;
              line-height: 16px;
              max-height: 48px;
              color: rgba(102, 102, 102, 1);
              word-break: break-all;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }

            .message-create {
              font-weight: normal;
              margin-top: 10px;
              margin-bottom: 10px;
            }
          }
        }

        .null {
          padding: 20px 0;
          color: #666;
          text-align: center;
          border-bottom: 1px #ccc solid;
        }
      }

      .message-toogle {
        li {
          display: inline-block;
          width: 50%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          /*border-top: 1px solid #cccccc;*/
          border-right: 1px solid #cccccc;
          cursor: pointer;
          font-size: 16px;
        }

        li:last-child {
          border-right: none;
        }
      }
    }
  }
}
</style>
<style lang="scss" lang="scss">
.notice-popover {
  min-width: 100px;
  padding: 20px 0px 0px 0px !important;
}

/*  .message-tooip {
        .el-popover {
             min-width: 100px;
            padding: 20px 0px 0px 0px !important;
         }

        .message-tooip {
             .el-popover {
                 min-width: 700px;
             }

         }
         .el-badge__content.is-fixed {
           top: 11px !important;
        }
    }*/
</style>
