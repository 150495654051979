import request from '@/utils/request';
import qs from 'qs';

// export function getOssConfig(params) {
//     return request({
//         url: '/v1/platform/upload/oss',
//         method: 'get',
//         params
//     })
// }

export function ossUpload(params, host) {
    console.log(params);
    return request({
        url: host,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })

}

// 公共 -- 未读消息数量
export function getunreadNum(params) {
    return request({
        url: `/v1/platfrom_un_read_num`,
        method: 'get',
        params: params
    })
}

// 公共 -- 消息列表
export function getNoticeList(params) {
    return request({
        url: `/v1/platfrom_message`,
        method: 'get',
        params: params
    })
}

export function clearUnread(params) {
    return request({
        url: `/v1/platfrom_read`,
        method: 'put',
        params: params
    })
}
//获取站点设置
export function getConfig() {
    return request({
      url: `/v1/cms/config`,
      method: 'get'
    })
  }
//P 端获S端计费中心基础信息
export function getSdomian() {
    return request({
        url: `/v1/platform/mp_basic_info`,
        method: 'get'
    })
}

/*
* 获取后端AIP域名
* */
export function getBasicInfo(params) {
    return request({
        url: `/v1/system/basic_info`,
        method: 'get',
        params
    })
}
// 图片上传OSS验证
export function getOssConfig(url, params) {
    return request({
        url: url + '/v2/ossUploadAuth',
        method: 'GET',
        params
    })
}

// 图片上传
export function uploader(url, data, header = {'Content-Type': 'multipart/form-data'}) {
    // if (!watchUpload) {
    //   watchUpload = function (v) {
    //     // // // console.log('ddd');
    //     // // // console.log(v);
    //   }
    // }
    return request({
        url,
        method: 'post',
        data,
        header,
        timeout: 6000000
    })
}

/*
* 获取存储相关配置信息
* */
export function storageConfig () {
    return request({
      url: '/v2/storage/config',
      method: 'GET',
    })
  }
  
  /*
  * 获取应用信息
  * */
  export function basicInfo () {
    return request({
      url: '/v2/basic_info',
      method: 'GET',
    })
  }
  
  /*
  * 获取媒资存储文件地址
  * */
  export function businessFolder (url,params) {
    return request({
      url: `${url}/v1/business_folder/get_folder`,
      method: 'GET',
      params
    })
  }
  
  export function ossUploadMamImageFiles(url, params) {
    return request({
      url: url + '/v1/oss_upload_auth',
      method: 'GET',
      params
    })
  }