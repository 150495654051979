import Cookies from 'js-cookie'
import { getLogin,getPermissions } from '@/api/user'
import store from '../store'
const TokenKey = 'Admin-Token'

export function getToken(TokenKey) {
  return Cookies.get(TokenKey)
}

export function setToken(TokenKey,token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

//获取用户信息
export function checkLogin() {
  getLogin().then(res => {
    store.commit('SET_USERINFO', res.data)
    Cookies.set(TokenKey, res.data.remember_token);
  }).catch(err => {
    console.log(err)
  });

}
//获取用户权限
export function getPermission(){
  getPermissions().then(res=>{
    // res.admin='0'
    sessionStorage.setItem('buttenpremissions',JSON.stringify(res))
  }).catch(err=>{
    console.log(err)
  })
}
